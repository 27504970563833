import React from 'react';
import './sticky-container.scss';

const StickyContainer = props => {
  const { children, style={} } = props;
  return (
    <div className="sticky-container" style={style}>
      { children }
    </div>
  );
}

export default StickyContainer;