import { useEffect } from "react";

import useGetQueryParams from "../../../hooks/use-get-query-params";
import useGet from "../../../hooks/use-get";

const useUserToken = (backendHost, setInviteFormValue) => {
  const { userToken } = useGetQueryParams();
  const [fetchUserData, userDataResponse, userDataLoading, userDataError] =
    useGet(`${backendHost}/api/v1/users/${userToken}`);

  useEffect(() => userToken && fetchUserData({}), []);

  useEffect(() => {
    if (userDataResponse) {
      setInviteFormValue("email", userDataResponse.email || "", {
        shouldValidate: true,
      });

      setInviteFormValue("firstName", userDataResponse.firstName || "", {
        shouldValidate: true,
      });

      setInviteFormValue("lastName", userDataResponse.lastName || "", {
        shouldValidate: true,
      });

      setInviteFormValue("title", userDataResponse.title || "", {
        shouldValidate: true,
      });

      setInviteFormValue("company", userDataResponse.guestCompany || "", {
        shouldValidate: true,
      });

      setInviteFormValue(
        "linkedinProfileUrl",
        userDataResponse.linkedinProfileUrl || "",
        { shouldValidate: true }
      );
    }
  }, [userDataResponse, userDataError]);
};

export default useUserToken;
