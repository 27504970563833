import useGetQueryParams from "../../../hooks/use-get-query-params";

// Components
import ConfirmationStep from "./ConfirmationStep";
import InviteFormStep from "./InviteFormStep";
import EmailConfirmationStep from "./EmailConfirmationStep";
import PaymentStep from "./PaymentStep";
import PlusOnesStep from "./PlusOnesStep";
import IcebreakerQuestionsStep from "./IcebreakerQuestionsStep";
import ConnectionStep from "./ConnectionStep";
import WaiverStep from "./WaiverStep";

const ActiveStep = (props) => {
	const {
		steps,
		activeStep,
		fields,
		event,
		redirectToStripeCheckout,
		registrationConfirmed,
		setRegistrationConfirmed,
		registrationCancelled,
		setRegistrationCancelled,
		handleNextStep,
		inviteFormRegister,
		inviteFormResponse,
		inviteFormState,
		inviteFormLoading,
		inviteFormResponseSucceed,
		inviteFormBackendErrorMsg,
		additionalTicketsFieldEnabled,
		hideBio,
		setHideBio,
		paymentAttempted,
		paymentConfirmed,
		questionsFormRegister,
		questionsFormControl,
		permalinkToken,
		inviteId,
		inviteEmail,
		plusOnesFormRegister,
		plusOnesFormValues,
		plusOnesFormSetValue,
		plusOnesFormState,
		plusOnesFormLoading,
		sendPlusOnesForm,
		currentPlusOnesIndex,
		plusOnesFormError,
		sendUpdateInvite,
		remainingPlusOnes,
		inviteHasCompletedWaiver,
		updateInviteLoading,
		linkedInOAuthUrl,
		inviteFormValues,
		inviteProfilePicture,
		inviteLandingPageUrl,
		inviteTicketAssigned,
		inviteMagicUrlForEventWalletModal,
		stepsComponent,
		inviteGuestRepId,
		atfrnpConstant,
		additionalTicketsFromAtfrnpParam,
		inviteRsvpStatus,
	} = props;

	const { icebreakerQuestions, waiverFileUrl } = event;
	const { allSet } = useGetQueryParams();
	const queryParamsInviteIdIsNumber = !isNaN(parseInt(inviteId));

	return (
		<div className="active-step-container">
			{activeStep === steps.confirmation && (
				<ConfirmationStep
					onYes={() => {
						if (!registrationConfirmed) {
							setRegistrationConfirmed(true);
						}

						handleNextStep();
					}}
					onNo={() => {
						if (queryParamsInviteIdIsNumber) {
							sendUpdateInvite({ invite: { rsvp_status: "declined" } });
						}

						setRegistrationCancelled(true);
					}}
					confirmed={registrationConfirmed}
					cancelled={registrationCancelled}
					event={event}
					additionalTicketsFromAtfrnpParam={additionalTicketsFromAtfrnpParam}
				/>
			)}

			{activeStep === steps.inviteForm && (
				<InviteFormStep
					fields={fields}
					register={inviteFormRegister}
					tiers={event.tiers}
					formState={inviteFormState}
					disabled={
						inviteFormLoading ||
						inviteFormResponseSucceed ||
						paymentAttempted ||
						allSet
					}
					event={event}
					backendErrorMsg={inviteFormBackendErrorMsg}
					additionalTicketsFieldEnabled={additionalTicketsFieldEnabled}
					guestRepId={inviteGuestRepId}
					inviteFormValues={inviteFormValues}
					atfrnpConstant={atfrnpConstant}
				/>
			)}

			{activeStep === steps.emailConfirmation && (
				<EmailConfirmationStep
					registrationEmailAddress={inviteEmail}
					inviteTicketAssigned={inviteTicketAssigned}
					sendUpdateInvite={sendUpdateInvite}
					inviteRsvpStatus={inviteRsvpStatus}
					updateInviteLoading={updateInviteLoading}
					inviteMagicUrlForEventWalletModal={inviteMagicUrlForEventWalletModal}
				/>
			)}

			{activeStep === steps.payment && (
				<PaymentStep
					redirectToStripeCheckout={() =>
						redirectToStripeCheckout(inviteFormResponse.stripe_session_id)
					}
					paymentAttempted={paymentAttempted}
					paymentConfirmed={paymentConfirmed}
					inviteEmail={inviteEmail}
				/>
			)}

			{activeStep === steps.plusOnes && (
				<PlusOnesStep
					register={plusOnesFormRegister}
					loading={plusOnesFormLoading}
					onSubmit={sendPlusOnesForm}
					values={plusOnesFormValues}
					setValue={plusOnesFormSetValue}
					formState={plusOnesFormState}
					errorResponse={plusOnesFormError}
					remainingPlusOnes={remainingPlusOnes}
					permittedPlusOnes={inviteFormResponse?.plusOnesPermitted}
					plusOnesData={inviteFormResponse?.plus_ones}
					currentPlusOnesIndex={currentPlusOnesIndex}
				/>
			)}

			{activeStep === steps.icebreakerQuestions && (
				<IcebreakerQuestionsStep
					register={questionsFormRegister}
					questions={icebreakerQuestions}
					control={questionsFormControl}
				/>
			)}

			{activeStep === steps.connection && (
				<ConnectionStep
					permalinkToken={permalinkToken}
					linkedInOAuthUrl={linkedInOAuthUrl}
					inviteId={inviteId}
					hideBio={hideBio}
					setHideBio={setHideBio}
					profilePictureUrl={inviteProfilePicture}
					inviteFormValues={inviteFormValues}
					inviteLandingPageUrl={inviteLandingPageUrl}
					stepsComponent={stepsComponent}
					sendUpdateInvite={sendUpdateInvite}
				/>
			)}

			{activeStep === steps.waiver && (
				<WaiverStep
					sendUpdateInvite={sendUpdateInvite}
					waiverRead={inviteHasCompletedWaiver}
					updateInviteLoading={updateInviteLoading}
					fileUrl={waiverFileUrl}
				/>
			)}
		</div>
	);
};

export default ActiveStep;
