import React, { useRef, useEffect } from 'react';
import SponsorCard from '../sponsor-card/sponsor-card';
import Slider from "react-slick";
import useGetWindowDimensions from '../../hooks/use-get-window-dimensions';
import './sponsor-ribbon.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SponsorRibbon = props => {
    const { screenWidth } = useGetWindowDimensions();
	const getSlidesForScreenWidth = () => {
		let slides;
		if (screenWidth < 800) slides = 1;
		if (screenWidth >= 800 && screenWidth < 1600) slides = 2;
		if (screenWidth >= 1600) slides = 5;
		return slides;
	};
	const sponsorsCount = props.sponsors.length;
	const slidesForScreenWidth = getSlidesForScreenWidth();
	const slidesToShow = Math.min(sponsorsCount, slidesForScreenWidth);
	const autoplayRequired = sponsorsCount > slidesToShow;
    const sponsorRibbonRef = useRef(null);
	useStyleSponsorRibbon({ sponsorRibbonRef, autoplayRequired, slidesToShow });
	const sliderSettings = {
		dots: false,
		arrows: false,
		infinite: true,
		autoplay: true,
		autoplaySpeed: 1500,
		speed: 100,
		slidesToShow,
		slidesToScroll: 1,
	};
	const { backgroundColor } = props;
	if (autoplayRequired) {
		return (
			<div ref={sponsorRibbonRef} id={props.id} className="sponsor-ribbon" style={{ backgroundColor }}>
				<Slider className='sponsor-ribbon-slider' {...sliderSettings}>
					{props.sponsors.map((sponsor, i) => {
						return (
							<div key={'sponsor-ribbon-' + i}>
								<SponsorCard className={`slides${slidesToShow}`} sponsor={sponsor} />
							</div>
						);
					})}
				</Slider>
			</div>
		);
	} else {
		// `sponsor-ribbon sponsor-ribbon-slider cards${slidesToShow}` //todel
		return (
			<div ref={sponsorRibbonRef} id={props.id} className='sponsor-ribbon sponsor-ribbon-slider' style={{ backgroundColor }}>
				{props.sponsors.map((sponsor, i) => {
					return (
						<div key={'sponsor-ribbon-' + i}>
							<SponsorCard sponsor={sponsor} />
						</div>
					);
				})}
			</div>
		);
	}
};

const useStyleSponsorRibbon = ({ sponsorRibbonRef, autoplayRequired, slidesToShow }) => {
	const { screenWidth } = useGetWindowDimensions();
    useEffect( () => {
        const sponsorRibbon = sponsorRibbonRef.current;
		if (sponsorRibbon && !autoplayRequired) {
            const width = {
                1: screenWidth < 800 ? '95%' : (
                    screenWidth < 960 ? '50%' : '35%'
                ),
                2: screenWidth < 800 ? '45%' : (
                    screenWidth < 960 ? '50%' : '35%'
                ),
            }[slidesToShow];
            sponsorRibbon.style = `grid-template: 100% / repeat(${slidesToShow}, ${width})`;
        }
	});
};

export default SponsorRibbon;
