// Components
import RegistrationAndNomination from "./registration-and-nomination";

// Hooks
import useGetQueryParams from "../../hooks/use-get-query-params";

// Styling
import "./event-item.scss";

const EventItem = ({
	event,
	showTicketsRemaining,
	showParkingPassesRemaining,
	customFields,
	guestTypes,
	guestReps,
}) => {
	const queryParams = useGetQueryParams();
	const { hbp } = queryParams;

	const renderSpotsRemaining = (event) => {
		if (event.remainingSpots === 0) {
			return <p className="sold-out">Sold out!</p>;
		}

		return (
			<>
				{hbp ? (
					<>
						{event.ticketsForSale === 0 ? (
							<p className="sold-out">No tickets for sale</p>
						) : (
							<p className="tickets-remaining">
								{`${event.ticketsForSale} ${
									event.ticketsForSale === 1 ? "ticket" : "tickets"
								} for sale`}
							</p>
						)}
					</>
				) : (
					<>
						{showTicketsRemaining && event.remainingSpots === 0 && (
							<p className="sold-out">Sold out!</p>
						)}

						{showTicketsRemaining && event.remainingSpots > 0 && (
							<p className="tickets-remaining">
								{`${event.remainingSpots} ${
									event.remainingSpots === 1 ? "ticket" : "tickets"
								} remaining`}
							</p>
						)}
					</>
				)}

				{!hbp && (
					<>
						{showParkingPassesRemaining &&
							event.remainingParkingPasses === 0 && (
								<p className="sold-out">No parking passes remaining!</p>
							)}

						{showParkingPassesRemaining && event.remainingParkingPasses > 0 && (
							<p className="parking-passes-remaining">
								{`${event.remainingParkingPasses} ${
									event.remainingParkingPasses === 1
										? "parking pass"
										: "parking passes"
								} remaining`}
							</p>
						)}
					</>
				)}
			</>
		);
	};

	const dateOptions = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const getEventDate = (event) => {
		const eventDate = new Date(event.dateTimeStart);
		const venueTimezone = event.venueTimezone;

		const dateString = eventDate.toLocaleDateString(undefined, dateOptions);

		const timeString = eventDate.toLocaleTimeString("en-US");
		const [hour, minute, second] = timeString.split(":");
		const period = second.split(" ")[1];
		const formattedTimeString = `${[hour, minute].join(":")} ${period}`;

		return `${dateString} at ${formattedTimeString} ${venueTimezone}`;
	};
	return (
		<div className="event-item">
			<div className="item-content">
				<div className="item-title">
					<p className="event-title">{event.title}</p>
					<p className="venue-name">({event.venueName})</p>
				</div>

				<p>{getEventDate(event)}</p>
				{renderSpotsRemaining(event)}
			</div>

			{event.remainingSpots > 0 && (
				<RegistrationAndNomination
					event={event}
					customFields={customFields}
					guestTypes={guestTypes}
					guestReps={guestReps}
				/>
			)}
		</div>
	);
};

export default EventItem;
