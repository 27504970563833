import { React, useState } from 'react';
import './sponsored-by-nav.scss';
import VerticalDivider from '../shared/dividers/vertical-divider';
import BaseModal from '../shared/modals/base-modal/base-modal';
import { IoIosArrowDropright } from "react-icons/io";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import parse from 'html-react-parser';

const AVIXA_IMG = 'https://avcdn.azureedge.net/production/images/default-source/Logos/avixa-460x300-image.jpg';

const SponsoredByNav = props => {
  const { height, sponsor } = props;
  const { backgroundColor, logo, textColor } = sponsor;
  const mainContainerStyles = { backgroundColor };
  if (height) mainContainerStyles.gridTemplateRows = height;
  const [showModal, setShowModal] = useState(false);

	return (
    <div className="sponsored-by-nav-main-container">
      <div
        className="sponsored-by-nav-content"
        style={mainContainerStyles}
      >
        <div className="sponsored-by-text" style={{ color: textColor }}>
          SPONSORED BY
        </div>
        <div className="logo" style={{backgroundImage: `url(${logo})`}}></div>
        <div className="separator">
          <VerticalDivider
            width='0.1vw'
            height='4vh'
            backgroundColor={textColor}
          />
        </div>
        <div className="special-offer-link">
          <a style={{ color: textColor }} onClick={() => setShowModal(true)}>
            <div className="offer-link-content-container">
              <div>
                View a special offer from our event sponsor
              </div>
              <IoIosArrowDropright size={18}/>
            </div>
          </a>
        </div>
      </div>
      {
        showModal && (
          <Modal
            sponsor={sponsor}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        )
      }
    </div>
  );
};

const Modal = props => {
  const { showModal, setShowModal, sponsor } = props;
  return (
    <BaseModal
      open={showModal}
      onClose={() => setShowModal(false)}
      showFooter={false}
      modalContentStyles={{
        borderRadius: '10px',
      }}
      modalBodyStyles={{borderTop: 0}}
    >
      <div className="special-offer-modal-container">
        <div className="special-offer-modal-left-col">
          <img src={sponsor.logo}/>
          <div className="social-media-links">
            <a href={sponsor.facebook}>{<FaFacebook size={40}/>}</a>
            <a href={sponsor.twitter}>{<FaTwitter size={40}/>}</a>
            <a href={sponsor.linkedin}>{<FaLinkedin size={40}/>}</a>
            <a href={sponsor.instagram}>{<FaInstagram size={40}/>}</a>
          </div>
          <div>
            {parse(sponsor.description)}
          </div>
        </div>
        <div className="special-offer-modal-right-col">
          <div className="responsive-embed">
            <iframe width="560" height="315" src={sponsor.virtual_link} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <div className="sponsor-offers-form-container">
            <div className="sponsor-offers-container">
              <h5>
                Sponsor content
              </h5>
              <p>
                Offers
              </p>
              <ul>
                {
                  sponsor.offers.map(
                    (offer, index) => {
                      return (
                        <li key={index}>
                          <a key={index} href={offer.url}>{offer.description}</a>
                        </li>
                      );
                    }
                  )
                }
              </ul>
            </div>
            <div className="form-container">
              <h5>
                Request More Info
              </h5>
              <input type="text" name="name" placeholder="Name" />
              <input type="text" name="email-or-phone" placeholder="Email or Phone" />
              <textarea placeholder="Message"></textarea>
              <button>Send</button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}

export default SponsoredByNav;