// Library Imports
import { loadStripe } from "@stripe/stripe-js";

let stripeId;

export const initStripe = () => {
	if (!window.location.pathname.includes("ticketing")) {
		return;
	}

	const splitUrl = window.location.pathname.split("/");
	const permalinkToken = splitUrl[2];

	const getData = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/stripe`
		);

		if (response.ok) {
			const data = await response.json();
			stripeId = data.stripeId;
		}
	};

	getData();
};

export const getStripePromise = () => {
	return loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
		stripeAccount: stripeId,
	});
};
