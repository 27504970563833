import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaGlobe,
} from "react-icons/fa";

import "./footer.scss";
import placeholder from "../../img/Rectangle 1.svg";

const LINKS = [
  {
    id: 1,
    name: "Privacy",
    path: "/",
  },
  {
    id: 2,
    name: "Terms",
    path: "/",
  },
  {
    id: 3,
    name: "About",
    path: "/",
  },
];

const Footer = (props) => {
  const [isRegistration, setIsRegistration] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== "/registration") {
      setIsRegistration((bool) => !bool);
    }
  }, [pathname]);

  const socialIcons = [
    {
      id: 1,
      url: props.facebookUrl,
      icon: <FaFacebook style={{ color: props.textColor }} size={30} />,
    },
    {
      id: 2,
      url: props.twitterUrl,
      icon: <FaTwitter style={{ color: props.textColor }} size={30} />,
    },
    {
      id: 3,
      url: props.linkedinUrl,
      icon: <FaLinkedin style={{ color: props.textColor }} size={30} />,
    },
    {
      id: 4,
      url: props.instagramUrl,
      icon: <FaInstagram style={{ color: props.textColor }} size={30} />,
    },
    {
      id: 5,
      url: props.websiteUrl,
      icon: <FaGlobe style={{ color: props.textColor }} size={30} />,
    },
  ].filter((net) => !!net.url);

  const renderRegistrationFooter = () => {
    return (
      <div className="footer-container">
        <div className="host-details">
          <h4>HOSTED BY:</h4>
          <h3>John Blair</h3>
          <p>Executive Vice President, Financial Instituition Group, SWBC</p>
        </div>
        <div className="links-group">
          <div className="img-wrapper">
            <img src={placeholder} alt="Logo" />
          </div>
          <ul className="links-list">
            {LINKS.map((link) => {
              const { id, name, path } = link;
              return (
                <li key={id}>
                  <a href={path}>{name}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  };

  const renderLandingFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
      <div
        className="landing-footer-main-container"
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}
      >
        <div className="landing-footer-social-media-container">
          <p className="text-weight">Follow {props.organizationName}</p>
          <ul className="social-icons">
            {socialIcons.map((socialIcon) => {
              const { id, url, icon } = socialIcon;
              return (
                <li key={id}>
                  <a href={url}>{icon}</a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="landing-footer-contact-container">
          <p className="text-weight">{props.organizationName}</p>
          <p>
            {props.address || "address"}, {props.phone || "phone"}
          </p>
          {/* 5201 N O'Connor Blvd, Irving, TX 75039, (214) 217-8484 */}
        </div>
        <div className="landing-footer-rights-reserved-container">
          <p>
            © {currentYear} {props.organizationName}. All Rights Reserved. All
            Logos and Brands used under license from their respective owners.
          </p>
          <div className="text-weight">
            <a
              href={props.privacyPolicy}
              target="_blank"
              style={{ color: props.textColor }}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <footer id={props.id}>
      {isRegistration ? renderRegistrationFooter() : renderLandingFooter()}
    </footer>
  );
};

export default Footer;
