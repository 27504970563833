import { useState, useEffect } from "react";

const useOnVideoGroupIntersection = (observerOptions) => {
	const [isIntersected, setIsIntersected] = useState(false);
	const [wrapperCache, setWrapperCache] = useState(0);
	const [wrapperHeight, setWrapperHeight] = useState(0);
	const [ref, setRef] = useState(null);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			const wrapper = entry.target.parentElement.querySelector(".video-group");
			const height = wrapper.offsetHeight;
			setWrapperCache(height);

			if (entry.isIntersecting) {
				setIsIntersected(false);

				setTimeout(function () {
					setWrapperHeight(0);
				}, 50);
				return;
			}
			if (entry.boundingClientRect.top < 0) {
				setTimeout(function () {
					setIsIntersected(true);
					setWrapperHeight(wrapperCache);
				}, 50);
			}
		}, observerOptions);

		if (ref) observer.observe(ref);

		// unmount
		return function () {
			if (ref) observer.unobserve(ref);
		};
	});
	return [setRef, isIntersected, wrapperHeight];
};

export default useOnVideoGroupIntersection;
