// Library Imports
import { backgroundStyle } from "../../lib/background";

// Components
import EventItem from "./event-item";

// Styling
import "./event-series-hero.scss";

const EventSeriesHero = ({ id, eventSeries, heroStyling }) => {
	const {
		title,
		description,
		events,
		showTicketsRemaining,
		showParkingPassesRemaining,
		customFields,
		guestTypes,
		guestReps,
	} = eventSeries;
	const { backgroundImage, backgroundColor } = heroStyling;

	const shouldRenderEvent = (event) => {
		const eventDate = new Date(event.dateTimeStart).getTime();
		const now = new Date().getTime();

		return eventDate > now;
	};

	return (
		<div id={id} className="event-series-hero alignfull">
			<div
				className="event-series-hero-background"
				style={backgroundStyle(backgroundImage, backgroundColor)}
			></div>
			<div className="event-series-hero-content-wrapper alignwide">
				<div className="event-series-hero-content">
					<div className="event-series-details">
						<h2>{title}</h2>
						<p>{description}</p>
					</div>

					<div className="events-container">
						<h2 className="title">Events</h2>

						<div className="container-items">
							{events?.map((event) => (
								<div
									className={`container-item ${
										shouldRenderEvent(event) ? "" : "d-none"
									}`}
									key={event.permalinkToken}
								>
									{shouldRenderEvent(event) && (
										<EventItem
											event={event}
											showTicketsRemaining={showTicketsRemaining}
											showParkingPassesRemaining={showParkingPassesRemaining}
											customFields={customFields}
											guestTypes={guestTypes}
											guestReps={guestReps}
										/>
									)}
								</div>
							))}

							{events?.length === 0 && (
								<p>No events are assigned to this Event Series</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventSeriesHero;
