// Library Imports
import { useContext } from "react";
import { TicketingFormContext } from "../../contexts/ticketing";
import { currencyFormatter } from "../../lib/currency";

const TotalPrice = () => {
	const { totalPrice } = useContext(TicketingFormContext);
	const totalPriceAsCurrency = currencyFormatter.format(totalPrice);

	return (
		<h3 className="total-price">
			Total:
			<span>{totalPriceAsCurrency}</span>
		</h3>
	);
};

export default TotalPrice;
