import React, { useEffect, useState } from "react";
import Hero from "../../components/hero/hero";
import VideoGroup from "../../components/video-group/video-group";
import { Attendees } from "../../components/attendees/attendees";
import Speakers from "../../components/speakers/speakers";
import SponsorRibbon from "../../components/sponsor-ribbon/sponsor-ribbon";
import Guests from "../../components/guests/guests";
import Resources from "../../components/resources/resources";
import Footer from "../../components/footer/footer";
import HostedByNav from "../../components/hosted-by-nav/hosted-by-nav";
import SponsoredByNav from "../../components/sponsored-by-nav/sponsored-by-nav";
import StickyContainer from "../../components/shared/containers/sticky-container/sticky-container";
import useOnVideoGroupIntersection from "../../hooks/use-on-video-group-intersection";
import useGet from "../../hooks/use-get";
import useGetConfigData from "../../hooks/use-get-config-data";
import ErrorPage from "../error-page/error-page";
import { useParams } from "react-router-dom";
import "./landing.scss";
import AppLoader from "../../components/shared/loaders/app-loader";
import _isEmpty from "lodash/isEmpty";
import useGetQueryParams from "../../hooks/use-get-query-params";
import usePartialUrl from "../../hooks/use-partial-url";
import useLocalStorage from "../../hooks/use-local-storage";

const Landing = () => {
	const [setVideoGroupRef, videoGroupIsIntersected, wrapperHeight] =
		useOnVideoGroupIntersection({ threshold: [0] });
	const { permalinkToken } = useParams();
	const configData = useGetConfigData();
	const { inviteId } = useGetQueryParams();
	const { REACT_APP_BACKEND_HOST } = configData;
	const [
		fetchLandingPageData,
		landingPageData,
		landingPageDataLoading,
		landingPageDataError,
	] = useGet(
		inviteId
			? `${REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/landing?invite_id=${inviteId}`
			: `${REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/landing`
	);

	useEffect(() => fetchLandingPageData({}), []);
	usePartialUrl({
		trigger: landingPageData,
		calculateScroll: (elementOffsetTop) => {
			const sticky = document.getElementsByClassName("sticky-container")[0];
			return elementOffsetTop - 2.5 * sticky.offsetHeight;
		},
		delay: 500,
	});
	const { welcomeMessageVisible } = useWelcomeMessage(inviteId);

	if (!landingPageData || landingPageDataLoading) {
		return (
			<main className="page">
				<AppLoader />
			</main>
		);
	}
	if (landingPageDataError)
		return <ErrorPage message="Landing Page not found" />;
	const featuredSponsor = landingPageData.sponsors.find(
		(x) => x.featured === true
	);
	const embeddedResources = landingPageData.resources.data.filter(
		(res) => !!res.embedded
	);
	const notEmbeddedResources = landingPageData.resources.data.filter(
		(res) => !res.embedded
	);
	const chatUrl =
		inviteId &&
		`${REACT_APP_BACKEND_HOST}/guest/engage/${permalinkToken}/chat?invite_id=${inviteId}`;
	return (
		<main className="page">
			<section className="alignfull">
				<StickyContainer>
					{videoGroupIsIntersected && landingPageData.videoGroup?.active && (
						<div className="sticky-video">
							<div className="responsive-embed">
								<iframe
									width="560"
									height="315"
									src={landingPageData.videoGroup.iframeVideoUrl}
									title="YouTube video player"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
								></iframe>
							</div>
						</div>
					)}
					<div className="hosted-and-sponsored-by">
						{landingPageData.host?.active && (
							<HostedByNav host={landingPageData.host} />
						)}
						{featuredSponsor && <SponsoredByNav sponsor={featuredSponsor} />}
					</div>
				</StickyContainer>
			</section>

			{landingPageData.hero?.active && (
				<Hero
					id="hero"
					{...landingPageData.hero}
					event={landingPageData.event}
					schedule={landingPageData.schedule.data}
					showSchedule={landingPageData.schedule.active}
				/>
			)}

			{landingPageData.videoGroup?.active && (
				<VideoGroup
					id="video-group"
					setRef={setVideoGroupRef}
					iframeVideoUrl={landingPageData.videoGroup.iframeVideoUrl}
					backgroundColor={landingPageData.videoGroup.backgroundColor}
					featuredSponsor={featuredSponsor}
					host={landingPageData.host}
					chatUrl={chatUrl}
				/>
			)}

			{landingPageData.event?.attendees?.length && (
				<section className="alignwide">
					<h2 className="section-title">Attendees</h2>
					<Attendees
						id="attendees"
						attendees={landingPageData.event.attendees}
					/>
				</section>
			)}

			{landingPageData.speakers?.active && (
				<section className="section-white alignwide">
					<h2 className="section-title">Speakers</h2>
					<Speakers id="speakers" speakers={landingPageData.speakers.data} />
				</section>
			)}
			{landingPageData.sponsorRibbon?.active &&
				!_isEmpty(landingPageData.sponsors) && (
					<section className="alignfull">
						<SponsorRibbon
							sponsors={landingPageData.sponsors}
							{...landingPageData.sponsorRibbon}
						/>
					</section>
				)}
			{landingPageData.specialGuests?.active && (
				<section className="section-white aligncenter">
					<h2 className="section-title">Special Guests</h2>
					<Guests id="guests" guests={landingPageData.specialGuests.data} />
				</section>
			)}

			{landingPageData.resources?.active && !_isEmpty(notEmbeddedResources) && (
				<section className="alignwide">
					<h2 className="section-title">Resources</h2>
					<Resources id="resources" resources={notEmbeddedResources} />
				</section>
			)}
			{landingPageData.resources?.active && !_isEmpty(embeddedResources) && (
				<section className="alignwide embedded-resources-container">
					<h2 className="section-title">Embedded Resources</h2>
					<Resources id="embedded-resources" resources={embeddedResources} />
				</section>
			)}
			{landingPageData.footer?.active && (
				<section className="alignfull">
					<Footer {...landingPageData.footer} />
				</section>
			)}
		</main>
	);
};

const useWelcomeMessage = (inviteId) => {
	const [welcomeMessageVisible, setWelcomeMessageVisible] = useState(false);
	const { getFromStorage, saveToStorage } = useLocalStorage();

	useEffect(() => {
		if (inviteId) {
			const persistenceKey = "already-see-landing-welcome-message-invite-ids";
			const value = getFromStorage(persistenceKey, []);
			if (_isEmpty(value)) {
				saveToStorage(persistenceKey, [inviteId]);
				setWelcomeMessageVisible(true);
			} else {
				if (!value.includes(inviteId)) {
					saveToStorage(persistenceKey, [...value, inviteId]);
					setWelcomeMessageVisible(true);
				}
			}
		}
	}, []);
	return { welcomeMessageVisible, setWelcomeMessageVisible };
};

export default Landing;
