import { useHistory } from "react-router-dom";
import useGetQueryParams from "./use-get-query-params";

const useAppHistory = () => {
  const history = useHistory();
  const queryParams = useGetQueryParams();

  const queryParamExist = key => {
    return !!queryParams[key];
  };

  const addQueryParams = params => {
    const keys = Object.keys(params);
    const newQueryParams = {...queryParams};
    keys.forEach(
      key => {
        newQueryParams[key] = encodeURIComponent(params[key]);
      }
    );
    const newQueryParamsKeys = Object.keys(newQueryParams);
    if (newQueryParamsKeys.length === 0) return;
    const newQueryParamsString = newQueryParamsKeys.map(
      key => `${key}=${newQueryParams[key]}`
    ).join('&');
    history.push(`${history.location.pathname}?${newQueryParamsString}`);
  };

  return { addQueryParams, queryParamExist };
}

const addIfNewKey = (obj, key, value) => {

};

export default useAppHistory;