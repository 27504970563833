import { React } from 'react';
import AppButton from '../app-button/app-button';
import AppLoader from '../../loaders/app-loader';
import './app-loading-button.scss';

const AppLoadingButton = props => {
  const {
    loading=false,
    size='small',
    disabled=false,
    onClick,
    children,
    variant='text',
    style={},
    className,
    color,
    href,
    loaderContainerStyles={},
  } = props;

  if (loading) return (
    <div className="app-loading-btn-loader-container" style={loaderContainerStyles}>
      <AppLoader size={25} />
    </div>
  );

  return (
    <AppButton
      color={color}
      href={href}
      className={className}
      style={style}
      size={size}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
    >
      {children}
    </AppButton>
  );
};

export default AppLoadingButton;