import React from 'react';

import './error-500-page.scss';

const Error500Page = () => {
  return (
    <div className="page-404">
      <div className="outer">
        <div className="middle">
          <div className="inner">
            <div className="inner-circle">
              <i className="fa fa-cogs"></i>
              <span>500</span>
            </div>
            <span className="inner-status">Oops! Internal Server Error!</span>
            <span className="inner-detail">Unfortunately we're having trouble loading the page you are looking for. Please try again.</span>
            <span className="inner-detail">If the problem continues, please email us at <a href='mailto:support@nextlevelfan.com'>support@nextlevelfan.com</a></span>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Error500Page;