import React from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { landingPageData } from '../../data/data';

const Bio = (props) => {
	let { pathname } = useLocation();
	let id = parseInt(props.match.params.profileId);
	let person;
	if (pathname.includes('speaker')) {
		person = landingPageData.speakers.find((x) => x.id === id);
	}
	if (pathname.includes('guest')) {
		person = landingPageData.guests.find((x) => x.id === id);
	}

	return (
		<main className="page">
			<section className="bio">
				<div className="featured-person" key={person.id}>
					<div className="person-image">
						<img src={person.image} alt={person.name} />
					</div>

					<div className="person-details">
						<h3>{person.name}</h3>
						<h4>
							{person.title} {person.company ? ' - ' + person.company : ''}
						</h4>
						{parse(person.bio)}
					</div>
				</div>
			</section>
		</main>
	);
};

export default Bio;
