import { useState, useEffect } from "react";

import usePost from "../../../hooks/use-post";
import useAppForm from "../../../hooks/use-app-form";

const usePlusOnesForm = (
	backendHost,
	inviteId,
	inviteDataResponse,
	setRemainingPlusOnes,
	setForceNextStep
) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const [
		sendPlusOnesForm,
		plusOnesFormResponse,
		plusOnesFormLoading,
		plusOnesFormError,
	] = usePost(`${backendHost}/api/v1/plus_ones`);

	const { register, handleSubmit, formState, watch, setValue, reset } =
		useAppForm({
			mode: "all",
			defaultValues: {
				inviteeId: null,
				firstName: "",
				lastName: "",
				email: "",
				isMinor: false,
				phone: "",
				guestCompany: "",
			},
		});

	const values = watch();

	const plusOnesFormResponseSucceed =
		!!plusOnesFormResponse && !plusOnesFormError && !plusOnesFormLoading;

	useEffect(() => {
		if (plusOnesFormResponseSucceed) {
			reset();

			if (currentIndex >= inviteDataResponse?.plus_ones?.length) {
				setRemainingPlusOnes((prev) => (prev > 0 ? prev - 1 : 0));
			}

			if (currentIndex + 1 >= inviteDataResponse?.plusOnesPermitted) {
				setForceNextStep(true);
			} else {
				setCurrentIndex((prev) => ++prev);
			}
		}
	}, [plusOnesFormResponseSucceed]);

	const onSubmit = (data) => {
		sendPlusOnesForm({
			plus_one: {
				invitee_id: data?.inviteeId,
				first_name: data.firstName,
				last_name: data.lastName,
				email: data.isMinor ? "" : data.email,
				is_minor: data.isMinor,
				phone: data.phone,
				guest_company: data.guestCompany,
			},
			inviter_id: inviteId,
		});
	};

	return {
		plusOnesFormRegister: register,
		plusOnesFormState: formState,
		sendPlusOnesForm: handleSubmit(onSubmit),
		plusOnesFormResponse,
		plusOnesFormError,
		plusOnesFormLoading,
		plusOnesFormValues: values,
		plusOnesFormSetValue: setValue,
		currentPlusOnesIndex: currentIndex,
	};
};

export default usePlusOnesForm;
