// Library Imports
import { useEffect, useState } from "react";
import axios from "axios";

const usePost = () => {
	const [params, setParams] = useState(null);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const sendData = async () => {
			try {
				setLoading(true);
				const response = await axios(params);
				setData(response.data);
				setError(null);
			} catch (err) {
				setError(err.response.data);
				setData(null);
			} finally {
				setLoading(false);
				setParams(null);
			}
		};

		if (params) {
			sendData();
		}
	}, [params]);

	return [setParams, data, loading, error];
};
export default usePost;
