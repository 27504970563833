import AppButton from "../../shared/buttons/app-button/app-button";

const PaymentStep = (props) => {
  const {
    redirectToStripeCheckout,
    paymentAttempted,
    paymentConfirmed,
    inviteEmail,
  } = props;

  return (
    <div className="payment-step-container">
      <h2 className="heading">Payment</h2>
      {!paymentAttempted && (
        <div className="subheading">
          <div>Thanks for the info!</div>
          <div>Next click below.</div>
        </div>
      )}
      {!paymentAttempted && (
        <div className="btn-wrapper">
          <AppButton size="small" onClick={redirectToStripeCheckout}>
            Pay Now
          </AppButton>
        </div>
      )}
      {paymentAttempted && !paymentConfirmed && (
        <div>The payment was cancelled</div>
      )}
      {paymentAttempted && paymentConfirmed && (
        <div className="subheading">
          <div>The payment was successful.</div>
          <div>Your receipt has been sent to {inviteEmail}.</div>
        </div>
      )}
    </div>
  );
};

export default PaymentStep;
