import Checkbox from "@mui/material/Checkbox";

const WaiverStep = (props) => {
  const { sendUpdateInvite, waiverRead, updateInviteLoading, fileUrl } = props;

  return (
    <div className="waiver-step-container">
      <h2 className="heading">Sign Waiver</h2>
      <div className="subheading">
        This is your last step...we promise. Please click below to view the
        waiver and click the checkbox to finish.
      </div>
      <div className="btn-wrapper">
        <a href={fileUrl} target="_blank" rel="noreferrer">
          Click here
        </a>
      </div>
      <div className="agree-input-wrapper">
        <label>
          I agree with the waiver
          <Checkbox
            checked={waiverRead}
            onChange={() =>
              sendUpdateInvite({
                invite: { has_completed_waiver: !waiverRead },
              })
            }
            disabled={updateInviteLoading}
          />
        </label>
      </div>
    </div>
  );
};

export default WaiverStep;
