import React, { useState } from "react";
import useGetConfigData from "../../hooks/use-get-config-data";
import { useParams } from "react-router-dom";
import _isEmpty from "lodash/isEmpty";
import MobileStepper from "@mui/material/MobileStepper";
import AppLoadingButton from "../shared/buttons/app-loading-button/app-loading-button";
import AppButton from "../shared/buttons/app-button/app-button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import AppLoader from "../shared/loaders/app-loader";
import useHistory from "../../hooks/use-history";

// Hooks
import useInviteData from "./hooks/useInviteData";
import useSteps from "./hooks/useSteps";
import useInviteForm from "./hooks/useInviteForm";
import useQuestionsForm from "./hooks/useQuestionsForm";
import usePlusOnesForm from "./hooks/usePlusOnesForm";
import useStripe from "./hooks/useStripe";
import usePaymentConfirmation from "./hooks/usePaymentConfirmation";
import useUpdateInvite from "./hooks/useUpdateInvite";
import useUserToken from "./hooks/useUserToken";
import useFillInviteFormWithInviteDataResponse from "./hooks/useFillInviteFormWithInviteDataResponse";
import useAtfrnpQueryParam from "./hooks/useAtfrnpQueryParam";
import useLinkedinOAuth from "./hooks/useLinkedInOAuth";

// Components
import ActiveStep from "./components/ActiveStep";

import "./registration-step-form.scss";

const RegistrationStepForm = (props) => {
	const { fields, event, stepsComponent } = props;

	const { REACT_APP_BACKEND_HOST } = useGetConfigData();
	const { addQueryParams } = useHistory();
	const { permalinkToken } = useParams();

	const [registrationConfirmed, setRegistrationConfirmed] = useState(false);
	const [registrationCancelled, setRegistrationCancelled] = useState(false);
	const [forceNextStep, setForceNextStep] = useState(false);

	const {
		hideBio,
		setHideBio,
		inviteId,
		setInviteId,
		inviteRsvpStatus,
		setInviteRsvpStatus,
		inviteEmail,
		setInviteEmail,
		remainingPlusOnes,
		setRemainingPlusOnes,
		plusOnesStepEnabled,
		setPlusOnesStepEnabled,
		inviteHasCompletedWaiver,
		setInviteHasCompletedWaiver,
		inviteProfilePicture,
		setInviteProfilePicture,
		inviteLandingPageUrl,
		setInviteLandingPageUrl,
		inviteTier,
		setInviteTier,
		inviteDataLoading,
		inviteDataResponseSucceed,
		inviteDataResponse,
		inviteTicketAssigned,
		setInviteTicketAssigned,
		inviteMagicUrlForEventWalletModal,
		setInviteMagicUrlForEventWalletModal,
	} = useInviteData(addQueryParams, REACT_APP_BACKEND_HOST);

	const linkedInOAuthUrl = useLinkedinOAuth(inviteId);
	const redirectToStripeCheckout = useStripe(event);

	const [icebreakerQuestions, setIcebreakerQuestions] = useState(
		event.icebreakerQuestions
	);
	const [icebreakerQuestionsStepEnabled, setIcebreakerQuestionsStepEnabled] =
		useState(!_isEmpty(event.icebreakerQuestions));

	const { paymentAttempted, paymentConfirmed, paymentConfirmationLoading } =
		usePaymentConfirmation(
			REACT_APP_BACKEND_HOST,
			setRegistrationConfirmed,
			setIcebreakerQuestions,
			setInviteId,
			setInviteHasCompletedWaiver,
			setRemainingPlusOnes,
			setPlusOnesStepEnabled,
			setIcebreakerQuestionsStepEnabled,
			setInviteRsvpStatus,
			setInviteEmail,
			setInviteProfilePicture,
			setInviteLandingPageUrl,
			setInviteTier,
			setInviteTicketAssigned,
			setInviteMagicUrlForEventWalletModal
		);

	const {
		inviteFormRegister,
		inviteFormState,
		sendInviteForm,
		inviteFormResponse,
		inviteFormLoading,
		inviteFormResponseSucceed,
		setInviteFormValue,
		inviteFormValues,
		inviteFormBackendErrorMsg,
		additionalTicketsFieldEnabled,
		setAdditionalTicketsFieldEnabled,
	} = useInviteForm(
		REACT_APP_BACKEND_HOST,
		inviteId,
		setInviteId,
		permalinkToken,
		setIcebreakerQuestions,
		setInviteHasCompletedWaiver,
		setRemainingPlusOnes,
		setPlusOnesStepEnabled,
		setIcebreakerQuestionsStepEnabled,
		event.tiers,
		setInviteEmail,
		setInviteRsvpStatus,
		paymentAttempted,
		setInviteProfilePicture,
		setInviteLandingPageUrl,
		setInviteTier,
		setInviteTicketAssigned,
		setInviteMagicUrlForEventWalletModal
	);

	useUserToken(REACT_APP_BACKEND_HOST, setInviteFormValue);

	const { inviteGuestRepId } = useFillInviteFormWithInviteDataResponse(
		setInviteFormValue,
		inviteDataResponseSucceed,
		inviteDataResponse,
		setInviteProfilePicture,
		setAdditionalTicketsFieldEnabled
	);

	const { atfrnpConstant, additionalTicketsFromAtfrnpParam } =
		useAtfrnpQueryParam(setInviteFormValue, setAdditionalTicketsFieldEnabled);

	const { sendUpdateInvite, updateInviteLoading } = useUpdateInvite(
		REACT_APP_BACKEND_HOST,
		inviteId,
		setInviteHasCompletedWaiver,
		setInviteRsvpStatus,
		setInviteTicketAssigned
	);

	const {
		questionsFormRegister,
		questionsFormControl,
		questionsFormResponseSucceed,
		sendQuestionsForm,
		questionsFormLoading,
	} = useQuestionsForm(icebreakerQuestions, REACT_APP_BACKEND_HOST, inviteId);

	const {
		plusOnesFormRegister,
		plusOnesFormValues,
		plusOnesFormSetValue,
		plusOnesFormState,
		plusOnesFormLoading,
		plusOnesFormError,
		sendPlusOnesForm,
		currentPlusOnesIndex,
	} = usePlusOnesForm(
		REACT_APP_BACKEND_HOST,
		inviteId,
		inviteDataResponse,
		setRemainingPlusOnes,
		setForceNextStep
	);

	const {
		activeStep,
		steps,
		maxSteps,
		handleNextStep,
		handleBackStep,
		nextStepButtonDisabled,
		backStepButtonDisabled,
	} = useSteps(
		event,
		inviteFormState,
		sendInviteForm,
		inviteFormResponseSucceed,
		paymentAttempted,
		paymentConfirmed,
		questionsFormResponseSucceed,
		sendQuestionsForm,
		plusOnesFormLoading,
		event.waiverFeatureEnabled,
		plusOnesStepEnabled,
		icebreakerQuestionsStepEnabled,
		remainingPlusOnes,
		addQueryParams,
		inviteTier,
		forceNextStep
	);

	return (
		<div className="registration-form-main-container">
			{paymentConfirmationLoading && inviteDataLoading && (
				<div className="loader-wrapper">
					<AppLoader />
				</div>
			)}

			{!paymentConfirmationLoading && (
				<ActiveStep
					steps={steps}
					activeStep={activeStep}
					fields={fields}
					event={event}
					redirectToStripeCheckout={redirectToStripeCheckout}
					registrationConfirmed={registrationConfirmed}
					setRegistrationConfirmed={setRegistrationConfirmed}
					registrationCancelled={registrationCancelled}
					setRegistrationCancelled={setRegistrationCancelled}
					handleNextStep={handleNextStep}
					inviteFormRegister={inviteFormRegister}
					inviteFormState={inviteFormState}
					inviteFormLoading={inviteFormLoading}
					inviteFormResponse={inviteFormResponse}
					inviteFormResponseSucceed={inviteFormResponseSucceed}
					inviteFormBackendErrorMsg={inviteFormBackendErrorMsg}
					additionalTicketsFieldEnabled={additionalTicketsFieldEnabled}
					paymentAttempted={paymentAttempted}
					paymentConfirmed={paymentConfirmed}
					questionsFormRegister={questionsFormRegister}
					questionsFormControl={questionsFormControl}
					hideBio={hideBio}
					setHideBio={setHideBio}
					permalinkToken={permalinkToken}
					inviteId={inviteId}
					inviteEmail={inviteEmail}
					plusOnesFormRegister={plusOnesFormRegister}
					plusOnesFormValues={plusOnesFormValues}
					plusOnesFormSetValue={plusOnesFormSetValue}
					plusOnesFormState={plusOnesFormState}
					plusOnesFormLoading={plusOnesFormLoading}
					sendPlusOnesForm={sendPlusOnesForm}
					currentPlusOnesIndex={currentPlusOnesIndex}
					plusOnesFormError={plusOnesFormError}
					sendUpdateInvite={sendUpdateInvite}
					remainingPlusOnes={remainingPlusOnes}
					inviteHasCompletedWaiver={inviteHasCompletedWaiver}
					updateInviteLoading={updateInviteLoading}
					linkedInOAuthUrl={linkedInOAuthUrl}
					inviteFormValues={inviteFormValues}
					inviteProfilePicture={inviteProfilePicture}
					inviteLandingPageUrl={inviteLandingPageUrl}
					inviteTicketAssigned={inviteTicketAssigned}
					inviteMagicUrlForEventWalletModal={inviteMagicUrlForEventWalletModal}
					stepsComponent={stepsComponent}
					inviteGuestRepId={inviteGuestRepId}
					atfrnpConstant={atfrnpConstant}
					additionalTicketsFromAtfrnpParam={additionalTicketsFromAtfrnpParam}
					inviteRsvpStatus={inviteRsvpStatus}
				/>
			)}

			<div className="stepper-wrapper">
				<MobileStepper
					steps={maxSteps}
					position="static"
					activeStep={activeStep}
					nextButton={
						<AppLoadingButton
							className="stepper-btn"
							loading={
								inviteFormLoading || questionsFormLoading || updateInviteLoading
							}
							onClick={handleNextStep}
							disabled={nextStepButtonDisabled}
							loaderContainerStyles={{ width: "80px" }}
						>
							{activeStep === steps.plusOnes && remainingPlusOnes !== 0
								? "Skip"
								: "Next"}
							<KeyboardArrowRight />
						</AppLoadingButton>
					}
					backButton={
						<AppButton
							className="stepper-btn"
							size="small"
							onClick={handleBackStep}
							disabled={backStepButtonDisabled}
						>
							<KeyboardArrowLeft />
							Back
						</AppButton>
					}
				/>
			</div>
		</div>
	);
};

export default RegistrationStepForm;
