import { useState, useEffect } from "react";

const useFillInviteFormWithInviteDataResponse = (
	setInviteFormValue,
	inviteDataResponseSucceed,
	inviteDataResponse,
	setInviteProfilePicture,
	setAdditionalTicketsFieldEnabled
) => {
	const [inviteGuestRepId, setInviteGuestRepId] = useState(null);

	useEffect(() => {
		if (inviteDataResponseSucceed) {
			setInviteProfilePicture(inviteDataResponse.linkedinProfilePictureUrl);

			setInviteFormValue(
				"firstName",
				inviteDataResponse.invitee.first_name || "",
				{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
			);

			setInviteFormValue(
				"lastName",
				inviteDataResponse.invitee.last_name || "",
				{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
			);

			setInviteFormValue("title", inviteDataResponse.invitee.title || "", {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});

			setInviteFormValue(
				"company",
				inviteDataResponse.invitee.guest_company || "",
				{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
			);

			setInviteFormValue("email", inviteDataResponse.invitee.email || "", {
				shouldValidate: true,
				shouldDirty: true,
				shouldTouch: true,
			});

			setInviteFormValue(
				"phone",
				inviteDataResponse.invitee.phone_number || "",
				{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
			);

			setInviteFormValue("guestRep", inviteDataResponse.guest_rep_id || "");

			setInviteGuestRepId(inviteDataResponse.invitee.guest_rep_id);

			if (inviteDataResponse.plusOnesPermitted) {
				setInviteFormValue(
					"additionalTickets",
					inviteDataResponse.plusOnesPermitted || "",
					{ shouldValidate: true, shouldDirty: true, shouldTouch: true }
				);

				setAdditionalTicketsFieldEnabled(false);
			}
		}
	}, [inviteDataResponseSucceed]);

	return { inviteGuestRepId };
};

export default useFillInviteFormWithInviteDataResponse;
