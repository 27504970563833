import IcebreakerQuestion from "./IcebreakerQuestion";

const IcebreakerQuestionsStep = (props) => {
  const { questions, register, control } = props;

  return (
    <div className="icebreaker-questions-step-container">
      <h2 className="heading">Tell Us About You</h2>
      <div className="subheading">
        Please feel free to answer any of the following questions:
      </div>
      <div className="questions-wrapper">
        {questions.map((question) => (
          <IcebreakerQuestion
            key={question.id}
            register={register}
            question={question}
            control={control}
          />
        ))}
      </div>
    </div>
  );
};

export default IcebreakerQuestionsStep;
