import { useState, useEffect } from 'react';

function useGetWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

function getDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    screenWidth: width,
    screenHeight: height
  };
}

export default useGetWindowDimensions;