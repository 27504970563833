import { React, useEffect } from 'react';
import './base-modal.scss';

const BaseModal = props => {
  const {
    open,
    onClose,
    title,
    showFooter=true,
    children,
    modalContentStyles={},
    modalBodyStyles={},
  } = props;
  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  }
  useEffect(
    () => {
      document.body.addEventListener('keydown', closeOnEscapeKeyDown);
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      };
    }, []
  );
  if (!open) return null;
  return (
    <div className="modal" onClick={onClose}>
      <div
        className="modal-content"
        onClick={e => e.stopPropagation()}
        style={modalContentStyles}
      >
        <div className="modal-header">
          {
            title && (
              <div className="modal-title">
                {title}
              </div>
            )
          }
          <div className="close-modal-x" onClick={onClose}>X</div>
        </div>
        <div className="modal-body" style={modalBodyStyles}>
          {children}
        </div>
        {
          showFooter && (
            <div className="modal-footer">
              <button onClick={onClose}>Close</button>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default BaseModal;