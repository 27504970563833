// Assets
import shootingEnvelope from "../../img/shooting-envelope.png";

const ConfirmationStep = () => {
	return (
		<>
			<h2 className="confirmation-step-title">✓ You're All Set!</h2>
			<div className="shooting-envelope-img-wrapper">
				<img src={shootingEnvelope} alt="shooting envelope" />
			</div>
			<p>
				You should have received an email receipt from us. We have also notified
				the seller that you have made a purchase.
			</p>
			<p>
				Hold tight! Your tickets will be sent to you whenever they become
				available.
			</p>
		</>
	);
};

export default ConfirmationStep;
