// React Routing
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import ErrorBoundary from "./components/shared/error-handlers/error-boundary";
import Error500Page from "./pages/error-page/error-500-page";
import Registration from "./pages/registration/registration";
import EventSeriesRegistration from "./pages/registration/event-series";
import Ticketing from "./pages/registration/ticketing";
import Landing from "./pages/landing/landing";
import Bio from "./pages/bio/bio";

// Styling
import "./styles/global.scss";

function App() {
	return (
		<ErrorBoundary fallback={<Error500Page />}>
			<Router>
				<Switch>
					<Route
						path="/registration/:permalinkToken"
						component={Registration}
					/>
					<Route
						path="/companies/:companyId/event-series/:eventSeriesId"
						component={EventSeriesRegistration}
					/>
					<Route path="/ticketing/:permalinkToken" component={Ticketing} />
					<Route path="/landing/:permalinkToken" component={Landing} />
					<Route exact path="/speaker/:profileId" component={Bio} />
					<Route exact path="/guest/:profileId" component={Bio} />
				</Switch>
			</Router>
		</ErrorBoundary>
	);
}

export default App;
