import { Controller } from "react-hook-form";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const IcebreakerQuestion = (props) => {
  const {
    register,
    control,
    question: { id, question, questionType, options },
  } = props;
  const getOptionById = (id) => {
    const option = options.find((opt) => Number(opt.id) === Number(id));
    return option ? option.option : `not found(${id})`;
  };

  return (
    <div className="ice-breaker-question-container">
      <div className="question-wrapper">{question}</div>
      {["text", "info"].includes(questionType) && (
        <input type="text" {...register(Number(id).toString())} />
      )}
      {questionType === "choice" && (
        <select {...register(Number(id).toString())}>
          {options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.option}
            </option>
          ))}
        </select>
      )}
      {questionType === "multi" && (
        <Controller
          className="multi-select-controller"
          name={Number(id).toString()}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              className="multi-select-input"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              multiple
              renderValue={(selecteds) =>
                selecteds.map((selected) => getOptionById(selected)).join(", ")
              }
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Checkbox checked={value.includes(option.id)} />
                  <ListItemText primary={option.option} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
      )}
    </div>
  );
};

export default IcebreakerQuestion;
