import ReactTooltip from "react-tooltip";
import { ErrorMessage } from "@hookform/error-message";

// Components
import AppLoadingButton from "../../shared/buttons/app-loading-button/app-loading-button";
import { useEffect } from "react";

const PlusOnesStep = ({
	register,
	values,
	setValue,
	loading,
	onSubmit,
	formState: { isValid, errors },
	errorResponse,
	remainingPlusOnes,
	permittedPlusOnes,
	plusOnesData,
	currentPlusOnesIndex,
}) => {
	let remainingPlusOnesMessage;

	const showRemainingPlusOnesMessage =
		(plusOnesData?.length &&
			remainingPlusOnes > 0 &&
			permittedPlusOnes > 0 &&
			currentPlusOnesIndex >= plusOnesData.length) ||
		(!plusOnesData?.length && remainingPlusOnes > 0 && permittedPlusOnes > 0);

	if (showRemainingPlusOnesMessage) {
		remainingPlusOnesMessage = `You have ${remainingPlusOnes} ${
			remainingPlusOnes === 1 ? "remaining ticket" : "remaining tickets"
		} to assign`;
	}

	useEffect(() => {
		if (plusOnesData.length && plusOnesData[currentPlusOnesIndex]) {
			if (plusOnesData[currentPlusOnesIndex].invitee?.is_minor) {
				setValue("isMinor", true);
			}

			setValue("inviteeId", plusOnesData[currentPlusOnesIndex].invitee?.id);
			setValue("email", plusOnesData[currentPlusOnesIndex].invitee?.email);
			setValue(
				"firstName",
				plusOnesData[currentPlusOnesIndex].invitee?.first_name
			);
			setValue(
				"lastName",
				plusOnesData[currentPlusOnesIndex].invitee?.last_name
			);

			setValue(
				"guestCompany",
				plusOnesData[currentPlusOnesIndex].invitee?.guest_company
			);
			setValue(
				"phone",
				plusOnesData[currentPlusOnesIndex].invitee?.phone_number
			);
		}
	}, [currentPlusOnesIndex]);

	return (
		<div className="plus-ones-step-container">
			<h2 className="heading">Add Your Guests</h2>

			{showRemainingPlusOnesMessage && (
				<div className="remaining-plus-ones-message">
					{remainingPlusOnesMessage}
				</div>
			)}

			{errorResponse && (
				<div className="error-msg response-error-msg">
					{errorResponse.errors}
				</div>
			)}

			<div className="plus-one-form-wrapper">
				<input type="hidden" {...register("inviteeId")} />

				<div>
					<label>
						First Name*
						<input
							type="text"
							{...register("firstName", {
								required: "First name is required",
							})}
						/>
					</label>

					<ErrorMessage
						errors={errors}
						name="firstName"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<div>
					<label>
						Last Name*
						<input
							type="text"
							{...register("lastName", {
								required: "Last name is required",
							})}
						/>
					</label>

					<ErrorMessage
						errors={errors}
						name="lastName"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<div>
					<label>
						{values.isMinor ? "Email" : "Email*"}
						<input
							type="text"
							{...register("email", {
								required: !values.isMinor && "Email is required",
								pattern: {
									value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
									message: "Email is not valid",
								},
							})}
							value={values.isMinor ? "" : values.email}
							disabled={values.isMinor}
						/>
					</label>

					<ErrorMessage
						errors={errors}
						name="email"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<div style={{ marginBottom: "30px" }}>
					<label>
						Skip Email Address
						<input
							className="is-minor-field"
							type="checkbox"
							{...register("isMinor")}
						/>
					</label>

					{values.isMinor && (
						<div className="warning-msg">
							⚠ Your guest will NOT receive a ticket or emails. You will be
							responsible to get this guest’s ticket.
						</div>
					)}
				</div>

				<div>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, max-content)",
							gap: "5px",
							alignItems: "end",
							justifyContent: "center",
						}}
					>
						<label>Mobile Number</label>
						<svg
							data-tip
							data-for="phone-field-tooltip"
							style={{ marginBottom: "4px" }}
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-question-circle-fill"
							viewBox="0 0 16 16"
						>
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
						</svg>

						<ReactTooltip
							id="phone-field-tooltip"
							place="right"
							type="dark"
							effect="solid"
						>
							<div style={{ maxWidth: "300px" }}>
								By providing your mobile number, you are opting-in to receive
								text and/or sms messages about your event. Message and data
								rates may apply. If you would like to opt-out of receiving these
								messages, please reply STOP to the text message.
							</div>
						</ReactTooltip>
					</div>

					<input type="text" {...register("phone")} />

					<ErrorMessage
						errors={errors}
						name="phone"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<label>
					Guest Company
					<input type="text" {...register("guestCompany")} />
				</label>

				<div className="submit-btn-wrapper">
					<AppLoadingButton
						loading={loading}
						onClick={onSubmit}
						variant="contained"
						disabled={!isValid}
					>
						Submit
					</AppLoadingButton>
				</div>
			</div>
		</div>
	);
};

export default PlusOnesStep;
