export const backgroundStyle = (backgroundImage, backgroundColor) => {
	if (backgroundImage && (!backgroundColor || backgroundColor === "")) {
		return {
			backgroundImage: buildBackgroundImage(backgroundImage, "rgb", "0, 0, 0"),
			backgroundRepeat: "no-repeat",
			backgroundAttachment: "center",
			backgroundPosition: "center",
			backgroundSize: "cover",
		};
	} else if (!backgroundImage && backgroundColor) {
		return { backgroundColor: backgroundColor };
	} else if (backgroundImage && backgroundColor) {
		const colorValueType = backgroundColor.includes("rgb") ? "rgb" : "hex";
		const gradientBase = getGradientBase(backgroundColor, colorValueType);

		return {
			backgroundImage: buildBackgroundImage(
				backgroundImage,
				colorValueType,
				gradientBase
			),
			backgroundRepeat: "no-repeat",
			backgroundAttachment: "center",
			backgroundPosition: "center",
			backgroundSize: "cover",
		};
	} else {
		return { backgroundColor: "rgba(0, 0, 0, 0.5)" };
	}
};

export const getGradientBase = (backgroundColor, colorValueType) => {
	let gradientBase = backgroundColor;

	if (colorValueType === "rgb") {
		const baseColor = backgroundColor.split("(");
		const colorValues = baseColor[1].split(",");

		if (colorValues.length > 3) {
			colorValues.pop();
		}

		gradientBase = colorValues.join(", ");
	} else if (backgroundColor === "black") {
		gradientBase = "#000000";
	} else if (backgroundColor === "white") {
		gradientBase = "#FFFFFF";
	}

	return gradientBase;
};

export const buildBackgroundImage = (
	backgroundImage,
	colorValueType,
	gradientBase
) => {
	return `linear-gradient(to right, ${
		colorValueType === "rgb"
			? "rgba(" + gradientBase + ", 0.6)"
			: gradientBase + "99"
	} 0%, ${
		colorValueType === "rgb"
			? "rgba(" + gradientBase + ", 0.865)"
			: gradientBase + "dd"
	} 50%, ${
		colorValueType === "rgb"
			? "rgba(" + gradientBase + ", 0.6)"
			: gradientBase + "99"
	} 100%), url('${backgroundImage}')`;
};
