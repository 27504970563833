import './error-page.scss';

const ErrorPage = props => {
  const { message } = props;
  return (
    <div className='error-page-container'>
      <div className='img-wrapper'></div>
      <div className='msgs-wrapper'>
        <div className='fixed-msg-wrapper'>
          This is embarrassing. It looks like there might be some configuration errors.<br/>Please refresh this page after addressing the following errors:
        </div>
        <div className='error-msg-wrapper'>
          <ul>
            <li>{message}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default ErrorPage;