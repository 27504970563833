import useGetConfigData from './use-get-config-data';

const useLinkedinOAuthUrl = state => {
  const {
    REACT_APP_LINKEDIN_CLIENT_ID, REACT_APP_LINKEDIN_OAUTH_REDIRECT_URL,
  } = useGetConfigData();
  return `https://www.linkedin.com/oauth/v2/authorization?client_id=${
    encodeURIComponent(REACT_APP_LINKEDIN_CLIENT_ID)
  }&redirect_uri=${
    encodeURIComponent(REACT_APP_LINKEDIN_OAUTH_REDIRECT_URL)
  }&response_type=code&scope=r_liteprofile+r_emailaddress&state=${encodeURIComponent(state)}`;
}
export default useLinkedinOAuthUrl;