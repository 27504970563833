// Library Imports
import { useState, useContext, useEffect } from "react";
import { MobileStepper } from "@mui/material";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import { backgroundStyle } from "../../lib/background";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePromise } from "../../lib/stripe";

// Components
import { TicketingFormContext } from "../../contexts/ticketing";
import CountdownTimer from "../countdown-timer/countdown-timer";
import InitialStep from "./initial-step";
import FormStep from "./form-step";
import PaymentStep from "./payment-step";
import ConfirmationStep from "./confirmation-step";
import AppLoadingButton from "../shared/buttons/app-loading-button/app-loading-button";
import AppButton from "../shared/buttons/app-button/app-button";

// Styling
import "./ticketing-step-form.scss";

const TicketingStepForm = ({
	id,
	title,
	description,
	location,
	backgroundImage,
	backgroundColor,
	countdownTimer,
}) => {
	const [stripePromise, setStripePromise] = useState();
	const { event, step, nextStep, previousStep, isLoading } =
		useContext(TicketingFormContext);

	useEffect(() => {
		setStripePromise(getStripePromise());
	}, []);

	const shouldRenderEvent = (event) => {
		const eventDate = new Date(event.dateTimeStart).getTime();
		const now = new Date().getTime();

		const passesAllValidation =
			eventDate > now &&
			event.availableTickets?.length > 0 &&
			event.paidProduct?.id;
		return passesAllValidation;
	};

	const maxSteps = 3;
	const paymentStep = 2;

	const paymentSubmittedIntent = new URLSearchParams(
		window.Location.search
	).get("payment_intent_client_secret");
	const clientSecret = paymentSubmittedIntent ?? localStorage.getItem("scs");

	const appearance = {
		theme: "stripe",
		variables: {
			colorPrimary: "#1565c0",
			colorBackground: "#f2f2f2",
			colorText: "#333333",
		},
	};

	const options = { clientSecret, appearance };

	return (
		<div id={id} className="ticketing-hero alignfull">
			<div
				className="ticketing-hero-background"
				style={backgroundStyle(backgroundImage, backgroundColor)}
			></div>

			<div className="ticketing-hero-content-wrapper alignwide">
				<div className="ticketing-hero-content">
					<div className="ticketing-details">
						<h2>{title}</h2>
						{description && <p>{description}</p>}

						{countdownTimer && (
							<CountdownTimer startTime={event.dateTimeStart} />
						)}
						<h3>{event.dateTimeStart}</h3>
						<p>{location}</p>
					</div>

					<div className="ticketing-container">
						{shouldRenderEvent(event) ? (
							<section>
								{step === 0 && <InitialStep />}
								{step === 1 && <FormStep />}

								{step === 2 && (
									<Elements options={options} stripe={stripePromise}>
										<PaymentStep />
									</Elements>
								)}

								{step === 3 && <ConfirmationStep />}

								<MobileStepper
									steps={maxSteps + 1}
									position="static"
									activeStep={step}
									backButton={
										<AppButton
											className="stepper-btn"
											size="small"
											onClick={previousStep}
											disabled={step === 0 || step === maxSteps}
										>
											<KeyboardArrowLeft />
											Back
										</AppButton>
									}
									nextButton={
										<AppLoadingButton
											className="stepper-btn"
											loading={isLoading}
											onClick={nextStep}
											disabled={step === paymentStep || step === maxSteps}
										>
											Next
											<KeyboardArrowRight />
										</AppLoadingButton>
									}
								/>
							</section>
						) : (
							<>
								<h2>Tickets For Sale</h2>
								<div className="error">
									<p>This event is not eligible for sale.</p>
									<p>Please contact your admin.</p>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TicketingStepForm;
