import { useLocation } from "react-router-dom";

const useGetQueryParams = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const queryParams = {};
  for(let pair of searchParams.entries()) {
    queryParams[pair[0]] = pair[1];
  }

  return queryParams;
};

export default useGetQueryParams;