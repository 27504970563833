// Library Imports
import { useContext, useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";

// Components
import { TicketingFormContext } from "../../contexts/ticketing";

// Styling
import "./checkout-form.scss";

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();

	const {
		event: { permalinkToken },
	} = useContext(TicketingFormContext);

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// stripe hasn't loaded yet
			// only allow form submissions after stripe has loaded
			return;
		}

		setIsLoading(true);

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/ticketing/${permalinkToken}?01110000=true`,
			},
		});

		// This will only be reached if there is an immediate error
		// thrown by stripe js -- otherwise, the user will be
		// redirected to the return url
		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}

		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "tabs",
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" options={paymentElementOptions} />
			<button disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">
					{isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
				</span>
			</button>

			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
};

export default CheckoutForm;
