// Library Imports
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Geocode from "react-geocode";
import _isEmpty from "lodash/isEmpty";

// Components
import AppLoader from "../../components/shared/loaders/app-loader";
import ErrorPage from "../error-page/error-page";
import Helmet from "../../components/shared/helmet/helmet";
import Hero from "../../components/hero/hero";
import SponsorRibbon from "../../components/sponsor-ribbon/sponsor-ribbon";
import Intro from "../../components/intro/intro";
import Speakers from "../../components/speakers/speakers";
import Guests from "../../components/guests/guests";
import Nav from "../../components/nav/nav";
import SponsorGrid from "../../components/sponsor-grid/sponsor-grid";
import Schedule from "../../components/schedule/schedule";
import AppMap from "../../components/shared/maps/app-map";
import Footer from "../../components/footer/footer";

// Hooks
import useGet from "../../hooks/use-get";
import useGetConfigData from "../../hooks/use-get-config-data";
import useGetQueryParams from "../../hooks/use-get-query-params";

// Styling
import "./registration.scss";

const Registration = () => {
	const queryParams = useParams();
	const configData = useGetConfigData();
	const { permalinkToken } = queryParams;
	const { REACT_APP_BACKEND_HOST } = configData;
	const { inviteId } = useGetQueryParams();

	const [
		fetchRegistrationPageData,
		registrationPageData,
		registrationPageDataLoading,
		registrationPageDataError,
	] = useGet(
		inviteId
			? `${REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/registration?invite_id=${inviteId}`
			: `${REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/registration`
	);

	const venueCoords = useGetCoordsFromAddress(
		registrationPageData?.event?.venueAddress
	);
	useEffect(() => fetchRegistrationPageData({}), [fetchRegistrationPageData]);

	useEffect(() => {
		if (registrationPageData) {
			document.querySelector(
				"title"
			).textContent = `${registrationPageData.event.title} | ${registrationPageData.event.subtitle}`;
		}
	}, [registrationPageData]);

	useScrollToAnchor(registrationPageData);

	if (registrationPageDataError) {
		return <ErrorPage message="Registration Page not found" />;
	}

	const featuredSponsor = registrationPageData?.sponsors?.find(
		(x) => x.featured === true
	);

	const navLinks = () => {
		const links = [];

		if (registrationPageData.hero?.active) {
			links.push({ name: "Top", link: "#hero" });
		}

		if (registrationPageData.intro?.active) {
			links.push({ name: "Intro", link: "#intro" });
		}

		if (registrationPageData.speakers?.active) {
			links.push({ name: "Hosts", link: "#speakers" });
		}

		if (registrationPageData.specialGuests?.active) {
			links.push({ name: "Guests", link: "#guests" });
		}

		if (!_isEmpty(registrationPageData.sponsors)) {
			links.push({ name: "Sponsors", link: "#sponsors" });
		}

		return links;
	};

	const showEventLocation =
		registrationPageData?.event?.inPerson &&
		!!venueCoords &&
		registrationPageData?.intro?.includeMap;

	return (
		<main className="page">
			{!registrationPageData || registrationPageDataLoading ? (
				<AppLoader />
			) : (
				<>
					<Helmet
						title={`${registrationPageData.event.title} | NextLevelFan`}
						description={registrationPageData.event.description}
						img={registrationPageData.hero.backgroundImage}
					/>

					{registrationPageData.navBar?.active && (
						<Nav navLinks={navLinks()} event={registrationPageData} />
					)}

					{registrationPageData.hero?.active && (
						<Hero
							id="hero"
							{...registrationPageData.hero}
							event={registrationPageData.event}
							showRegistrationForm={registrationPageData.formFields.active}
							registrationStepFormFields={registrationPageData.formFields}
							featuredSponsor={featuredSponsor}
							stepsComponent={registrationPageData.steps}
						/>
					)}

					{registrationPageData.sponsorRibbon?.active &&
						!_isEmpty(registrationPageData.sponsors) && (
							<section className="alignfull">
								<SponsorRibbon
									id="sponsor_ribbon"
									sponsors={registrationPageData.sponsors}
									{...registrationPageData.sponsorRibbon}
								/>
							</section>
						)}

					{registrationPageData.intro?.active && (
						<section className="section-white aligncenter">
							<Intro id="intro" intro={registrationPageData.intro} />
						</section>
					)}

					{showEventLocation && (
						<section className="section-white aligncenter map-wrapper">
							<AppMap
								defaultCenter={{
									latitude: venueCoords.lat,
									longitude: venueCoords.lng,
								}}
								markers={[
									{ latitude: venueCoords.lat, longitude: venueCoords.lng },
								]}
							/>
						</section>
					)}

					{registrationPageData.schedule?.active && (
						<section className="section-white aligncenter">
							<Schedule
								id="schedule"
								schedule={registrationPageData.schedule.data}
							/>
						</section>
					)}

					{registrationPageData.speakers?.active && (
						<section className="section-white aligncenter">
							<h2 className="section-title">Hosts</h2>
							<Speakers
								id="speakers"
								speakers={registrationPageData.speakers.data}
							/>
						</section>
					)}

					{registrationPageData.specialGuests?.active && (
						<section className="section-white aligncenter">
							<h2 className="section-title">Special Guests</h2>
							<Guests
								id="guests"
								guests={registrationPageData.specialGuests.data}
							/>
						</section>
					)}

					{!_isEmpty(registrationPageData.sponsors) && (
						<section className="section-white aligncenter">
							<h2 className="section-title">Sponsors</h2>
							<SponsorGrid
								id="sponsors"
								sponsors={registrationPageData.sponsors}
							/>
						</section>
					)}

					{registrationPageData.footer?.active && (
						<section className="alignfull">
							<Footer id="footer" {...registrationPageData.footer} />
						</section>
					)}
				</>
			)}
		</main>
	);
};

const useGetCoordsFromAddress = (address) => {
	const { REACT_APP_GOOGLE_MAPS_API_KEY } = useGetConfigData();
	const [coords, setCoords] = useState(null);

	useEffect(() => {
		if (!address) {
			return;
		}

		Geocode.setApiKey(REACT_APP_GOOGLE_MAPS_API_KEY);
		Geocode.fromAddress(address)
			.then((response) => {
				setCoords(response.results[0].geometry.location);
			})
			.catch((error) => {
				console.error("Error getting coordinates from address", address);
				console.error(error);
			});
	}, [address, REACT_APP_GOOGLE_MAPS_API_KEY]);
	return coords;
};

const useScrollToAnchor = (registrationPageData) => {
	useEffect(() => {
		if (registrationPageData) {
			const id = window.location.hash;

			if (!id) {
				return;
			}

			let element;
			setTimeout(() => {
				element = document.querySelector(id);

				if (element) {
					element.scrollIntoView();
				}
			}, 100);
		}
	}, [registrationPageData]);
};

export default Registration;
