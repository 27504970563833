import React from 'react';
import './offers.scss';

export const Offer = (props) => {
	return (
		<li className="offer">
			<a className="offer-link card" href={props.offer.alternative}>
				<div className="offer-details">
					<div className="label">Special offer from {props.sponsor.name}</div>
					{props.offer.description}
				</div>
				<div className="offer-image">
					<img src={props.sponsor.logo} alt={props.sponsor.name + ' Logo'} />
				</div>
			</a>
		</li>
	);
};
const Offers = (props) => {
	return (
		<ul className="offers">
			{props.sponsors.map((sponsor) => {
				return sponsor.offers.map((offer, i) => {
					return <Offer key={'offer-' + i} sponsor={sponsor} offer={offer} />;
				});
			})}
		</ul>
	);
};

export default Offers;
