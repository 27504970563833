// Library Imports
import { useContext } from "react";
import { ErrorMessage } from "@hookform/error-message";

// Contexts
import { NominationFormContext } from "../../contexts/nomination-form";

// Styling
import "./custom-fields-form.scss";

const CustomFieldsForm = () => {
	const {
		customFields,
		setMultiselectValues,
		setFileValues,
		nominationFormRegister,
		disabled,
		errors,
	} = useContext(NominationFormContext);

	const customFieldInput = (customField, formRegistration) => {
		switch (customField.field_type) {
			case "choice":
				const responseOptions = customField.options.split("\r\n");

				return (
					<select {...formRegistration} disabled={disabled} defaultValue="">
						<option disabled value="">
							Select an option...
						</option>

						{responseOptions.map((responseOption, idx) => (
							<option key={`response-option-${idx}`} value={responseOption}>
								{responseOption}
							</option>
						))}
					</select>
				);
			case "multi":
				const multiResponseOptions = customField.options.split("\r\n");

				const handleCheckboxChange = (e) => {
					setMultiselectValues((prev) => {
						const currentVal = [...prev[customField.id]];

						if (currentVal.includes(e.target.value)) {
							const itemIdx = currentVal.indexOf(e.target.value);
							currentVal.splice(itemIdx, 1);
						} else {
							currentVal.push(e.target.value);
						}

						return {
							...prev,
							[customField.id]: currentVal,
						};
					});
				};

				return (
					<div className="multiselect-checkbox-group">
						{multiResponseOptions.map((responseOption, idx) => (
							<label key={`checkbox-for-${customField.id}-${idx}`}>
								<input
									type="checkbox"
									value={responseOption}
									onChange={handleCheckboxChange}
									disabled={disabled}
								/>
								{responseOption}
							</label>
						))}
					</div>
				);
			case "image":
				const handleFileUpload = (e) => {
					setFileValues((prev) => ({
						...prev,
						[customField.id]: e.target.files[0],
					}));
				};

				return (
					<input
						type="file"
						{...formRegistration}
						onChange={handleFileUpload}
						disabled={disabled}
						accept="image/png,image/jpeg,image/gif"
					/>
				);
			default:
				return <input type="text" {...formRegistration} disabled={disabled} />;
		}
	};

	return (
		<div className="custom-fields-form">
			<h4 className="form-title">Custom Fields</h4>

			{customFields.map((customField) => {
				if (!customField.active) {
					return <></>;
				}

				let formRegistrationOptions = {};

				if (customField.required) {
					formRegistrationOptions.required = "This field is required";
				}

				const formRegistration = nominationFormRegister(
					String(customField.id),
					formRegistrationOptions
				);

				return (
					<div key={`custom-field-${customField.id}`} className="form-group">
						<label>
							{customField.visible_name + (customField.required ? "*" : "")}
							{customFieldInput(customField, formRegistration)}
						</label>

						<ErrorMessage
							errors={errors}
							name={`custom-field-${customField.id}`}
							render={({ message }) => <p className="error-msg">{message}</p>}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default CustomFieldsForm;
