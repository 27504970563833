import { useForm } from "react-hook-form";
import { useEffect } from "react";

const useAppForm = (options = {}, persistenceOptions = {}) => {
	const {
		register,
		handleSubmit,
		watch,
		formState,
		setValue,
		getValues,
		control,
		reset,
		clearErrors,
		setError,
		trigger,
	} = useForm(options);

	useFormPersist(persistenceOptions.key, watch, setValue, {
		...persistenceOptions,
	});

	return {
		register,
		handleSubmit,
		watch,
		formState,
		getValues,
		setValue,
		control,
		reset,
		clearErrors,
		setError,
		trigger,
	};
};

const useFormPersist = (
	key,
	watch,
	setValue,
	{
		storage,
		exclude = [],
		include,
		onDataRestored,
		validate = false,
		dirty = false,
		fillFormWithPersistedData = true,
	} = {}
) => {
	const getStorage = () => storage || localStorage;
	let values;

	if (key) {
		values = watch(include);
	}

	useEffect(() => {
		if (key && fillFormWithPersistedData) {
			const str = getStorage().getItem(key);

			if (str) {
				const values = JSON.parse(str);
				const dataRestored = {};

				Object.keys(values).forEach((key) => {
					const shouldSet = !exclude.includes(key);

					if (shouldSet) {
						dataRestored[key] = values[key];

						setValue(key, values[key], {
							shouldValidate: validate,
							shouldDirty: dirty,
						});
					}
				});

				if (onDataRestored) {
					onDataRestored(dataRestored);
				}
			}
		}
	}, [key, fillFormWithPersistedData]);

	useEffect(() => {
		if (key) {
			getStorage().setItem(key, JSON.stringify(values));
		}
	});

	return {
		clear: () => key && getStorage().removeItem(key),
	};
};

export default useAppForm;
