import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

import useGetConfigData from "../../../hooks/use-get-config-data";

const useStripe = (event) => {
  const { REACT_APP_STRIPE_PUBLISHABLE_KEY } = useGetConfigData();
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const { paid, stripeAccountId } = event;
      if (paid) {
        const stripeResponse = await loadStripe(
          REACT_APP_STRIPE_PUBLISHABLE_KEY,
          {
            stripeAccount: stripeAccountId,
          }
        );
        setStripe(stripeResponse);
      }
    };

    getData();
  }, []);

  const redirectToStripeCheckout = (stripeSessionId) => {
    stripe
      .redirectToCheckout({
        sessionId: stripeSessionId,
      })
      .then(function (result) {
        console.log("There was an error processing your payment: " + result);
      });
  };

  return redirectToStripeCheckout;
};

export default useStripe;
