// Library Imports
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

// Components
import AppLoader from "../../components/shared/loaders/app-loader";
import ErrorPage from "../error-page/error-page";
import Helmet from "../../components/shared/helmet/helmet";
import Nav from "../../components/nav/nav";
import TicketingFormContextProvider from "../../contexts/ticketing";
import TicketingStepForm from "../../components/ticketing-step-form/ticketing-step-form";
import Footer from "../../components/footer/footer";
import { initStripe } from "../../lib/stripe";

initStripe();

const Ticketing = () => {
	const [axiosError, setAxiosError] = useState();
	const [data, setData] = useState();

	const queryParams = useParams();
	const { permalinkToken } = queryParams;

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/paid_tickets`
				);

				setData(response.data);
			} catch (error) {
				setAxiosError(error);
			}
		};

		getData();
	}, [permalinkToken]);

	const navLinks = [
		{
			name: "Top",
			link: "#top",
		},
		{
			name: "Footer",
			link: "#footer",
		},
	];

	if (axiosError) {
		return <ErrorPage message="Ticketing Page not found" />;
	}

	return (
		<main className="page">
			{!data ? (
				<AppLoader />
			) : (
				<>
					<Helmet
						title={`${data.event.title} | Ticketing`}
						descripton={data.event.description}
						img={data.hero.backgroundImage}
					/>

					<Nav navLinks={navLinks} {...data.navBar} />

					<TicketingFormContextProvider event={data.event}>
						<TicketingStepForm id="top" {...data.hero} />
					</TicketingFormContextProvider>

					<section className="alignfull">
						<Footer id="footer" {...data.footer} />
					</section>
				</>
			)}
		</main>
	);
};

export default Ticketing;
