// Components
import AppButton from "../../shared/buttons/app-button/app-button";

const ConfirmationStep = (props) => {
	const {
		onYes,
		onNo,
		confirmed,
		cancelled,
		event,
		additionalTicketsFromAtfrnpParam,
	} = props;

	let { remainingSpots, totalSpots, ticketsData } = event;
	const originalRemainingSpots = remainingSpots;
	let thereAreRemainingSpots = remainingSpots > 0;

	const generateHeading = () => {
		if (cancelled) {
			return <h2 className="heading">Thank you for your response!</h2>;
		}

		if (ticketsData && ticketsData !== "") {
			return (
				<div className="registration-details">
					<div className="header">
						<h2 className="heading colored">Registration Form</h2>
						<p>Follow the steps below</p>
					</div>

					<section>
						<div className="row">
							<div className="step-number">
								<div>1</div>
								<div>|</div>
								<div>|</div>
							</div>

							<div className="step-details">
								<h4>Registration Form</h4>
								<p>Complete registration form for you and your guests</p>
							</div>
						</div>

						<div className="row">
							<div className="step-number">
								<div>2</div>
								<div>|</div>
								<div>|</div>
							</div>

							<div className="step-details">
								<h4>Confirmation Email</h4>
								<p>
									Wait for confirmation email and look out for periodic emails
									with important event info
								</p>
							</div>
						</div>

						<div className="row">
							<div className="step-number">
								<div>3</div>
								<div>|</div>
								<div>|</div>
							</div>

							<div className="step-details">
								<h4>Get Tickets</h4>
								<p>
									1-3 days before the event, we'll notify you to get your
									tickets
								</p>
							</div>
						</div>
					</section>
				</div>
			);
		}

		return <h2 className="heading">RSVP Here</h2>;
	};

	if (additionalTicketsFromAtfrnpParam) {
		const additionalSpots = additionalTicketsFromAtfrnpParam + 1; /* we add 1
    to additionalTicketsFromAtfrnpParam because it doesn't include the ticket
    of the invite, it just count the amount of +1s of the invite */
		remainingSpots = remainingSpots - additionalSpots;
		thereAreRemainingSpots = remainingSpots >= 0;
	}

	const showRemainingSpotsMessage =
		!thereAreRemainingSpots || originalRemainingSpots <= totalSpots * 0.1;

	return (
		<div className="confirmation-step-container">
			<>{generateHeading()}</>

			{showRemainingSpotsMessage && (
				<div className="remaining-spots-msg">
					{thereAreRemainingSpots
						? `Only ${originalRemainingSpots} ${
								originalRemainingSpots === 1 ? "spot" : "spots"
						  } remaining for this event.`
						: "There are not enough tickets left for this event. Please reach out to your event manager for assistance"}
				</div>
			)}

			{thereAreRemainingSpots && !cancelled && (
				<>
					<div className="subheading">Are you able to make it?</div>

					<div className="confirmation-btns">
						<AppButton size="small" variant="contained" onClick={onYes}>
							Yes
						</AppButton>

						{!confirmed && (
							<AppButton size="small" variant="contained" onClick={onNo}>
								No
							</AppButton>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default ConfirmationStep;
