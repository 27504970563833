import { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";

import useGetQueryParams from "../../../hooks/use-get-query-params";
import useGet from "../../../hooks/use-get";

const usePaymentConfirmation = (
	backendHost,
	setRegistrationConfirmed,
	setIcebreakerQuestions,
	setInviteId,
	setInviteHasCompletedWaiver,
	setRemainingPlusOnes,
	setPlusOnesStepEnabled,
	setIcebreakerQuestionsStepEnabled,
	setInviteEmail,
	setInviteRsvpStatus,
	setInviteProfilePicture,
	setInviteLandingPageUrl,
	setInviteTier,
	setInviteTicketAssigned,
	setInviteMagicUrlForEventWalletModal
) => {
	const { orderId, paymentCancelled } = useGetQueryParams();
	const [paymentAttempted, setPaymentAttempted] = useState(false);

	const [fetchOrderData, orderDataResponse, orderDataLoading, orderDataError] =
		useGet(`${backendHost}/api/v1/invite_orders/${orderId}`);

	useEffect(() => {
		const orderIdIsNumber = !isNaN(parseInt(orderId));
		const paymentCancelledIsNumber = !isNaN(parseInt(paymentCancelled));

		if (orderIdIsNumber || paymentCancelledIsNumber) {
			setPaymentAttempted(true);
			setRegistrationConfirmed(true);
		}
		if (orderIdIsNumber) fetchOrderData({});
	}, []);

	useEffect(() => {
		if (orderDataResponse?.invite) {
			const invite = orderDataResponse.invite;

			if (invite.icebreakerQuestions) {
				setIcebreakerQuestions(invite.icebreakerQuestions);
				setIcebreakerQuestionsStepEnabled(
					!_isEmpty(invite.icebreakerQuestions)
				);
			}

			if (invite.id) {
				setInviteId(invite.id);
			}

			setInviteEmail(invite.invitee.email);
			setInviteRsvpStatus(invite.rsvp_status);
			setRemainingPlusOnes(invite.plusOnesRemaining);
			setPlusOnesStepEnabled(invite.plusOnesPermitted);
			setInviteHasCompletedWaiver(invite.hasCompletedWaiver);
			setInviteProfilePicture(invite.linkedinProfilePictureUrl);
			setInviteLandingPageUrl(invite.landingPageUrl);
			setInviteTier({ free: invite.tierIsFree });
			setInviteTicketAssigned(invite.ticketAssigned);
			setInviteMagicUrlForEventWalletModal(invite.magicUrlForEventWalletModal);
		}
	}, [orderDataResponse]);

	const paymentConfirmed =
		!!orderDataResponse && !orderDataError && orderDataResponse.paid;

	return {
		paymentAttempted,
		paymentConfirmed,
		paymentConfirmationLoading: orderDataLoading,
	};
};

export default usePaymentConfirmation;
