// Library Imports
import { useLocation } from "react-router-dom";

// Components
import RegistrationStepForm from "../registration-step-form/registration-step-form";
import RegisterOtherForm from "../register-other-form/register-other-form";

// Styling
import "./registration-form-container.scss";

const RegistrationFormContainer = (props) => {
	const { registrationStepFormFields, event, stepsComponent, featuredSponsor } =
		props;

	const urlParams = useLocation().search;
	const registerSomeoneElse = urlParams.includes("rse=true");

	return (
		<div className="registration-form-container">
			{registerSomeoneElse ? (
				<>
					<div className="nav-button-wrapper">
						<a
							id="register-yourself-button"
							href={`/registration/${event.permalinkToken}`}
						>
							Or register yourself
						</a>
					</div>
					<div className="hero-feature">
						<RegisterOtherForm
							event={event}
							featuredSponsor={featuredSponsor}
						/>
					</div>
				</>
			) : (
				<div className="hero-feature">
					<RegistrationStepForm
						fields={registrationStepFormFields}
						event={event}
						stepsComponent={stepsComponent}
						featuredSponsor={featuredSponsor}
					/>
				</div>
			)}
		</div>
	);
};

export default RegistrationFormContainer;
