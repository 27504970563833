import { useState, useEffect } from "react";

import useGetQueryParams from "../../../hooks/use-get-query-params";

const useSteps = (
	event,
	inviteFormState,
	sendInviteForm,
	inviteFormResponseSucceed,
	paymentAttempted,
	paymentConfirmed,
	questionsFormResponseSucceed,
	sendQuestionsForm,
	plusOnesFormLoading,
	waiverStepEnabled,
	plusOnesStepEnabled,
	icebreakerQuestionsStepEnabled,
	remainingPlusOnes,
	addQueryParams,
	inviteTier,
	forceNextStep
) => {
	const { allSet = false } = useGetQueryParams();

	const stepNames = [
		"confirmation",
		"inviteForm",
		"payment",
		"plusOnes",
		"emailConfirmation",
		"icebreakerQuestions",
		"waiver",
		"connection",
	];

	const steps = {};

	const setSteps = () => {
		let currentStep = 0;
		let shouldIncreaseCurrentStep;

		stepNames.forEach((name) => {
			shouldIncreaseCurrentStep = true;

			switch (name) {
				case "payment":
					if (event.paid && !inviteTier.free) {
						steps[name] = currentStep;
					} else {
						shouldIncreaseCurrentStep = false;
					}
					break;
				case "plusOnes":
					if (plusOnesStepEnabled) {
						steps[name] = currentStep;
					} else {
						shouldIncreaseCurrentStep = false;
					}
					break;
				case "icebreakerQuestions":
					if (icebreakerQuestionsStepEnabled) {
						steps[name] = currentStep;
					} else {
						shouldIncreaseCurrentStep = false;
					}
					break;
				case "waiver":
					if (waiverStepEnabled) {
						steps[name] = currentStep;
					} else {
						shouldIncreaseCurrentStep = false;
					}
					break;
				default:
					steps[name] = currentStep;
					break;
			}

			if (shouldIncreaseCurrentStep) {
				currentStep += 1;
			}
		});
	};

	setSteps();

	const [activeStep, setActiveStep] = useState(steps.confirmation);
	const maxSteps = Object.keys(steps).length;
	const lastStepIndex = maxSteps - 1;

	const nextStepButtonDisabled =
		activeStep === steps.confirmation ||
		(activeStep === steps.inviteForm &&
			(paymentAttempted ? false : !inviteFormState.isValid)) ||
		(activeStep === steps.payment && !paymentConfirmed) ||
		(activeStep === steps.plusOnes && plusOnesFormLoading) ||
		activeStep === lastStepIndex;

	const backStepButtonDisabled =
		activeStep === steps.confirmation ||
		activeStep ===
			(plusOnesStepEnabled ? steps.plusOnes : steps.icebreakerQuestions);

	const handleNextStep = () => {
		if (
			activeStep === steps.inviteForm &&
			!inviteFormResponseSucceed &&
			!paymentAttempted
		) {
			sendInviteForm();
		} else if (activeStep === steps.icebreakerQuestions) {
			sendQuestionsForm();
		} else if (activeStep === steps.plusOnes && remainingPlusOnes !== 0) {
			if (
				window.confirm(
					"ALERT: This is a required form in order to receive all of your tickets. If you choose to SKIP now, you will need to add your guest name(s) later."
				)
			) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}
		} else {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	useEffect(() => {
		if (forceNextStep) {
			handleNextStep();
		}
	}, [forceNextStep]);

	const handleBackStep = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	useEffect(() => {
		inviteFormResponseSucceed &&
			activeStep === steps.inviteForm &&
			handleNextStep();
	}, [inviteFormResponseSucceed]);

	useEffect(() => {
		questionsFormResponseSucceed &&
			activeStep === steps.icebreakerQuestions &&
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
	}, [questionsFormResponseSucceed]);

	useEffect(() => {
		if (paymentAttempted) {
			if (paymentConfirmed && allSet) {
				setActiveStep(lastStepIndex);
			} else {
				setActiveStep(steps.payment);
			}
		}
	}, [paymentAttempted, paymentConfirmed]);

	useEffect(() => {
		if (allSet) {
			setActiveStep(lastStepIndex);
		}
	}, []);

	useEffect(() => {
		if (activeStep === steps.connection) {
			addQueryParams({ allSet: true });
		}
	}, [activeStep]);

	return {
		activeStep,
		steps,
		maxSteps,
		handleNextStep,
		handleBackStep,
		nextStepButtonDisabled,
		backStepButtonDisabled,
	};
};

export default useSteps;
