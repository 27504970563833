import { React } from 'react';
import './hosted-by-nav.scss';
import VerticalDivider from '../shared/dividers/vertical-divider';

const HostedByNav = props => {
  const { height } = props;
  const { backgroundColor, logo, textColor, description } = props.host;
  const mainContainerStyles = { backgroundColor };
  if (height) mainContainerStyles.gridTemplateRows = height;
	return (
    <div
      className="hosted-by-nav-main-container"
      style={mainContainerStyles}
    >
      <div className="hosted-by-text" style={{ color: textColor }}>
        HOSTED BY
      </div>
      <div className="logo" style={{backgroundImage: `url(${logo})`}}></div>
      <div className="separator">
        <VerticalDivider
          width='0.1vw'
          height='4vh'
          backgroundColor={textColor}
        />
      </div>
      <div className="description" style={{ color: textColor }}>
        { description }
      </div>
    </div>
  );
};

export default HostedByNav;