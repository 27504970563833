import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import shootingEnvelope from "../../../img/shooting-envelope.png";
import thumbsUpImg from "../../../img/thumbs-up.svg";

// Components
import AppButton from "../../shared/buttons/app-button/app-button";

const EmailConfirmationStep = (props) => {
	const {
		registrationEmailAddress,
		inviteTicketAssigned,
		sendUpdateInvite,
		inviteRsvpStatus,
		updateInviteLoading,
		inviteMagicUrlForEventWalletModal,
	} = props;
	const [emailConfirmed, setEmailConfirmed] = useState(false);

	const handleCheckboxChange = () => {
		setEmailConfirmed(!emailConfirmed);
	};

	useEffect(() => {
		if (inviteRsvpStatus === "accepted") return;
		sendUpdateInvite({ invite: { rsvp_status: "accepted" } });
	}, []);

	const renderThumbsUp = () => {
		if (emailConfirmed) {
			return (
				<div className="email-confirmation-thumbsup">
					<div className="thumbsup-img-wrapper">
						<img src={thumbsUpImg} alt="thumbs up" />
					</div>
				</div>
			);
		}
	};

	return (
		<div className="email-confirmation-step-container">
			<div className="shooting-envelope-img-wrapper">
				<img src={shootingEnvelope} alt="shooting envelope" />
			</div>

			{updateInviteLoading ? (
				<div className="confirming-tickets-spinner-container">
					<span>Confirming your Tickets...</span>
					<ClipLoader
						color={"#1976D1"}
						loading={true}
						size={32}
						aria-label="Loading Spinner"
						className="loading-spinner"
					/>
				</div>
			) : inviteTicketAssigned ? (
				<div>
					<div className="email-confirmation-title">
						<h2>Confirmed.</h2>
						<h2>Your ticket is ready!</h2>
					</div>

					<div className="email-confirmation-details">
						To secure your ticket(s) and parking (if applicable) now, please
						click the button below and follow the prompts.
					</div>

					<div className="tickets-ready-btn-container">
						<AppButton
							size="small"
							variant="contained"
							onClick={() => {
								window.open(inviteMagicUrlForEventWalletModal, "_blank");
							}}
						>
							ACCEPT TICKETS NOW
						</AppButton>
					</div>

					<div className="email-confirmation-details">
						Or, to finish registering and get them at the end, please click
						Next.
					</div>
				</div>
			) : (
				<div>
					<div className="email-confirmation-title">
						<h2>Confirmation Email Sent.</h2>
						<div className="registration-email-address">
							({registrationEmailAddress})
						</div>
					</div>

					<div className="email-confirmation-details">
						Please confirm you have received the email. If you find it in spam,
						please mark it as not spam. Most future communication from us,
						including this event's tickets, will be sent via email.
					</div>

					<div className="email-confirmation-details">
						1-3 days before the event, we'll notify you to get your tickets.
					</div>

					<div className="email-confirmation-checkbox-wrapper">
						<div className="checkbox-inner-wrapper">
							<input
								type="checkbox"
								id="email-confirmation-checkbox"
								onChange={handleCheckboxChange}
							/>
							<span className="checkmark"></span>
						</div>

						<p>I found the email</p>
					</div>
					{renderThumbsUp()}
				</div>
			)}
		</div>
	);
};

export default EmailConfirmationStep;
