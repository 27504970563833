import { useEffect } from "react";

import useGetQueryParams from "../../../hooks/use-get-query-params";

const useAtfrnpQueryParam = (
  setInviteFormValue,
  setAdditionalTicketsFieldEnabled
) => {
  //The atfrnp(additional tickets field random number param) query param
  //is going to store a value given by this formula:
  //atfrnp = atfv - atfrnpConstant
  //where atfv = additional tickets field value
  //and atfrnpConstant = is a constant number that substracts the field value
  //The purpose of the atfrnp query param is to prevent the user from knowing
  //what the true value of the additional tickets field is
  const atfrnpConstant = 928;
  const queryParams = useGetQueryParams();
  const atfrnpParsed = parseInt(queryParams.atfrnp);
  const atfrnpQueryParamIsNumber = !isNaN(atfrnpParsed);
  let additionalTicketsFromAtfrnpParam = atfrnpQueryParamIsNumber
    ? atfrnpParsed + atfrnpConstant
    : null;

  useEffect(() => {
    if (atfrnpQueryParamIsNumber) {
      setInviteFormValue(
        "additionalTickets",
        additionalTicketsFromAtfrnpParam,
        { shouldValidate: true, shouldDirty: true, shouldTouch: true }
      );
      setAdditionalTicketsFieldEnabled(false);
    }
  }, []);

  return { atfrnpConstant, additionalTicketsFromAtfrnpParam };
};

export default useAtfrnpQueryParam;
