const useLocalStorage = receivedStorage => {
  const storage = receivedStorage || localStorage || window.sessionStorage;
  const getFromStorage = (key, defaultValue=null) => {
    const value = storage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  };
  const saveToStorage = (key, value) => {
    storage.setItem(key, JSON.stringify(value));
  };
  return { getFromStorage, saveToStorage };
};

export default useLocalStorage;