import React from 'react';
import parse from 'html-react-parser';
import './guests.scss';

const Guest = (props) => {
	const guest = props.guest;

	return (
		<div className="guest" key={guest.id}>
			<div className="guest-header">
				<img src={guest.image} alt="" />

				<div className="details">
					<h3>{guest.name}</h3>
					<h4>{guest.title}</h4>
				</div>
			</div>

			<div className="bio">{parse(guest.bio)}</div>
		</div>
	);
};
const Guests = (props) => {
	return (
		<div id={props.id} className="guests">
			<ul className="guests-list">
				{props.guests.map((guest, index) => {
					return <Guest guest={guest} key={'guest-' + index} />;
				})}
			</ul>
		</div>
	);
};

export default Guests;
