import { React } from 'react';

const VerticalDivider = props => {
  const { width, height, backgroundColor } = props;
  return (
    <div style={{
      borderLeft: `${width} solid ${backgroundColor}`,
      height,
    }}>
    </div>
  );
};

export default VerticalDivider;