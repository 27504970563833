import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePartialUrl = (params={}) => {
  const { hash } = useLocation();
  const { trigger, calculateScroll, delay } = params;
  if (!calculateScroll) calculateScroll = elementOffsetTop => elementOffsetTop;
  const scrollToHash = () => {
    if (hash) {
      const id = hash.replaceAll('#', '');
      const element = document.getElementById(id);
      if (element) {
        window.scroll({
          top: calculateScroll(element.offsetTop),
          behavior: 'smooth'
        });
      }
    }
  };
  useEffect(
    () => {
      if (delay && delay > 0) {
        setTimeout(scrollToHash, delay);
      } else {
        scrollToHash();
      }
    }, [trigger]
  );
};

export default usePartialUrl;