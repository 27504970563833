// Library Imports
import { useContext } from "react";

// Components
import AppLoader from "../shared/loaders/app-loader";

// Contexts
import { NominationFormContext } from "../../contexts/nomination-form";

// Styling
import "./confirmation-step.scss";

const ConfirmationStep = () => {
	const {
		loading,
		nominationFormBackendErrorMsg,
		sendNominationFormSucceeded,
		successfulNominationResponse,
	} = useContext(NominationFormContext);

	return (
		<div className="nomination-confirmation">
			{loading ? (
				<div className="app-loader-wrapper">
					<AppLoader />
				</div>
			) : (
				<div className="form-status">
					{nominationFormBackendErrorMsg && (
						<p className="error-msg">{nominationFormBackendErrorMsg}</p>
					)}

					{sendNominationFormSucceeded && (
						<div className="form-submit-succeeded">
							<p className="form-submit-message">
								{successfulNominationResponse.new_invite_created
									? `Successfully nominated ${successfulNominationResponse.invite.invitee_email}`
									: `Successfully updated information for existing nominee: ${successfulNominationResponse.invite.invitee_email}`}
							</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default ConfirmationStep;
