import useGetQueryParams from "../../../hooks/use-get-query-params";
import useLinkedinOAuthUrl from "../../../hooks/use-linkedin-oauth-url";

const useLinkedinOAuth = (inviteId) => {
  const { orderId } = useGetQueryParams();
  const linkedInOAuthUrl = useLinkedinOAuthUrl(
    orderId ? `order-${orderId}` : `invite-${inviteId}`
  );
  return linkedInOAuthUrl;
};

export default useLinkedinOAuth;
