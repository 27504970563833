import { React } from 'react';
import Button from '@mui/material/Button';
import './app-button.scss';

const AppButton = props => {
  const {
    size='small',
    disabled=false,
    onClick,
    children,
    variant='text',
    style={},
    className,
    color,
    href,
  } = props;

  return (
    <Button
      color={color}
      href={href}
      className={className ? `app-btn ${className}` : 'app-btn'}
      style={style}
      size={size}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
    >
      {children}
    </Button>
  );
};

export default AppButton;