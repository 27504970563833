import React from 'react';
import parse from 'html-react-parser';

import './intro.scss';

const Intro = (props) => {
	const introCopy = props.intro.copy ? parse(props.intro.copy.replace(/\n/g, "<br>")) : '';
	return (
		<div id={props.id} className="intro">
			<h2 className="div-title">{props.intro.heading}</h2>
			{ introCopy }
		</div>
	);
};

export default Intro;
