import React from 'react';
import './schedule.scss';

const getDateName = (dateSring, timeZone) => {
	const date = new Date(dateSring);
	const day = date.toLocaleString('en-US', {
		day: 'numeric',
		weekday: 'long',
		month: 'long',
		timeZone,
	});
	return day;
};

const getTimeOfDay = (dateTimeSring, timeZone) => {
	const date = new Date(dateTimeSring);
	const time = date.toLocaleString('en-US', {
		hour: 'numeric',
		minute: 'numeric',
		timeZone,
	});
	return time;
};

const ScheduleEvent = (props) => {
	let event = props.event;
	return (
		<div>
			<div className="event-details label">
				<time className="label" dateTime={event.startDateTime}>
					{getTimeOfDay(event.startDateTime, event.timeZone)}
				</time>
				{event.location && (' • ' + event.location)}
			</div>

			<h4>{event.title}</h4>
			{
        event.speaker && (
          <h5>{event.speaker}</h5>
        )
      }
		</div>
	);
};
const ScheduleDay = (props) => {
	let events = props.events.filter((event) => {
		return getDateName(event.startDateTime, event.timeZone) === props.dateName;
	});
	return (
		<ul>
			{events.map((event, i) => {
				return (
					<li className="schedule-event" key={'schedule-event-' + i}>
						<ScheduleEvent event={event} />
					</li>
				);
			})}
		</ul>
	);
};
const Schedule = (props) => {
	const dates = [];

	props.schedule.forEach((event) => {
		let date = getDateName(event.startDateTime, event.timeZone);
		if (!dates.includes(date)) {
			dates.push(date);
		}
	});
	return (
		<div id={props.id} className="schedule">
			<ul className="schedule-event-list">
				{dates.map((date, i) => {
					return (
						<li className="card" key={'schedule-date-' + i}>
							<h3>{date}</h3>
							<ScheduleDay events={props.schedule} dateName={date} />
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default Schedule;
