const InitialStep = () => {
	return (
		<>
			<div className="header">
				<h2>Tickets For Sale</h2>
				<p>Follow the steps below</p>
			</div>

			<div className="row">
				<div className="step-number">
					<div>1</div>
					<div>|</div>
					<div>|</div>
				</div>

				<div className="step-details">
					<h4>Ticket Purchase Form</h4>
					<p>Complete ticket purchase form for you and your guests.</p>
				</div>
			</div>

			<div className="row">
				<div className="step-number">
					<div>2</div>
					<div>|</div>
					<div>|</div>
				</div>

				<div className="step-details">
					<h4>Submit Payment</h4>
					<p>
						Pay with credit/debit card. Wait for confirmation email, and look
						out for periodic emails with important event info.
					</p>
				</div>
			</div>

			<div className="row">
				<div className="step-number">
					<div>3</div>
					<div>|</div>
					<div>|</div>
				</div>

				<div className="step-details">
					<h4>Get Tickets</h4>
					<p>
						As soon as your tickets become available, they will be sent to you.
					</p>
				</div>
			</div>
		</>
	);
};

export default InitialStep;
