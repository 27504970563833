// Library Imports
import { Helmet as ReactHelmet } from "react-helmet";

const Helmet = ({ title, description, img }) => {
	return (
		<ReactHelmet>
			<title>{title}</title>
			<meta name="description" content={description}></meta>
			<meta property="og:title" content={title} />
			<meta property="og:url" content={window.location.href} />
			<meta property="og:image" content={img} />
		</ReactHelmet>
	);
};

export default Helmet;
