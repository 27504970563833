import { React } from 'react';
import parse from 'html-react-parser';
import SponsorCard from '../sponsor-card/sponsor-card';
import { Offer } from '../offers/offers';
import Swiper from 'react-id-swiper';
import SwiperCore, { Lazy, Virtual, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import './video-group.scss';
SwiperCore.use([Lazy, Virtual, Autoplay]);

const VideoGroupSponsor = (props) => {
	return (
		<div className="video-sponsor-wrapper">
			<SponsorCard sponsor={props.sponsor} />

			<div className="sponsor-description">{parse(props.sponsor.description)}</div>
		</div>
	);
};

const OfferSlider = (props) => {
	const params = {
		rebuildOnUpdate: true,
		spaceBetween: 30,
		centeredSlides: true,
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
	};
	return (
		<Swiper {...params}>
			{props.offers.map((offer, i) => {
				return (
					<div key={'offer-' + i}>
						<Offer sponsor={props.sponsor} offer={offer} />
					</div>
				);
			})}
		</Swiper>
	);
};

const VideoGroup = (props) => {
	const {
    setRef, featuredSponsor, chatUrl, backgroundColor, iframeVideoUrl
  } = props;

	let getBackgroundColor = () => {
		return { backgroundColor };
	};

	return (
		<div id={props.id} className={'video-group dark-ui alignfull'} ref={setRef} style={getBackgroundColor()}>
			<div className="spacer"></div>

			<div className="video-wrapper" style={getBackgroundColor()}>
        <div className="video">
					<div className="responsive-embed">
            <iframe width="560" height="315" src={iframeVideoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
				</div>
        {
          chatUrl && (
            <div className="chat-wrapper">
              <iframe
                src={chatUrl}
                title="Chat"
                width="100%"
                height="100%"
              >
              </iframe>
            </div>
          )
        }
        {
          featuredSponsor && (
            <div className="video-offers">
              <div className="offer-slider-wrapper">
                <OfferSlider sponsor={featuredSponsor} offers={featuredSponsor.offers} />
              </div>
            </div>
          )
        }
			</div>
		</div>
	);
};

export default VideoGroup;
