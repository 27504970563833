import React, { useState } from 'react';
import parse from 'html-react-parser';
import './speakers.scss';

const Speaker = (props) => {
	const speaker = props.speaker;
	const [isExpanded, setIsExpanded] = useState(false);
	const toggle = () => {
		setIsExpanded((s) => !s);
	};

	return (
		<div className="speaker" key={speaker.id}>
			<img src={speaker.image} alt="" />

			<div className="details">
				<h3>{speaker.name}</h3>
				<h4>
					{speaker.title} - {speaker.company}
				</h4>
				<button onClick={toggle} className="link">
					{isExpanded ? '- Hide Bio' : '+ Show Bio'}
				</button>
			</div>
			{
				speaker?.bio && (
					<div className={isExpanded === true ? 'bio show' : 'bio hide'}>{parse(speaker.bio)}</div>
				)
			}
		</div>
	);
};

const Speakers = (props) => {
	return (
		<div id={props.id} className="speakers">
			{props.speakers.map((speaker, index) => {
				if (speaker.featured) {
					return (
						<div className="featured-speaker" key={index}>
							<div className="speaker-image">
								<img src={speaker.image} alt={speaker.name} />
							</div>

							<div className="speaker-details">
								<div className="label">Primary Host</div>
								<h3>{speaker.name}</h3>
								<h4>
									{speaker.title} - {speaker.company}
								</h4>
								{parse(speaker.bio)}
							</div>
						</div>
					);
				} else {
					return null;
				}
			})}

			<div className="speakers-list">
				{props.speakers.map((speaker, index) => {
					if (!speaker.featured) {
						return <Speaker speaker={speaker} key={'speaker-' + index} />;
					} else {
						return null;
					}
				})}
			</div>
		</div>
	);
};

export default Speakers;
