import React from 'react';
import parse from 'html-react-parser';
import SponsorLinks from '../sponsor-links/sponsor-links';

import './sponsor-grid.scss';

const SponsorTeir = (props) => {
	let sponsors = props.sponsors.filter((sponsor) => {
		return sponsor.group_type_name === props.tier;
	});
	return (
		<div className="sponsor-list-wrapper">
			<h3 className="label">{props.tier}</h3>
			<div className="sponsor-list">
				{sponsors.map((sponsor, i) => {
					return (
						<div key={'sponsor-' + i} className="sponsor-item card">
							<div className="sponsor-header">
								<img src={sponsor.logo} alt={sponsor.name + ' Logo'} />
								<div className="sponsor-detials">
									<h4 className="sponsor-name">{sponsor.name}</h4>
									<SponsorLinks sponsor={sponsor} />
								</div>
							</div>
							<div className="sponsor-description">{parse(sponsor.description)}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
const SponsorGrid = (props) => {
	let teirs = [];

	props.sponsors.forEach((sponsor) => {
		if (!teirs.includes(sponsor.group_type_name)) {
			teirs.push(sponsor.group_type_name);
		}
	});

	return (
		<div id={props.id} className="sponsor-grid">
			{teirs.map((teir, i) => {
				return <SponsorTeir key={'teir-' + i} sponsors={props.sponsors} tier={teir} />;
			})}
		</div>
	);
};

export default SponsorGrid;
