// Library Imports
import { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { ErrorMessage } from "@hookform/error-message";

// Assets
import { NominationFormContext } from "../../contexts/nomination-form";

// Styling
import "./nominee-details-form.scss";

const NomineeDetailsForm = () => {
  const {
    nominationFormRegister,
    disabled,
    errors,
    event,
    guestTypes,
    guestReps,
    states,
  } = useContext(NominationFormContext);

  return (
    <div className="nominations-form">
      <h4 className="form-title">Nominee Details</h4>

      {/* First Name */}
      <div>
        <label>
          First Name*
          <input
            type="text"
            {...nominationFormRegister("firstName", {
              required: "First name is required",
            })}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Last Name */}
      <div>
        <label>
          Last Name*
          <input
            type="text"
            {...nominationFormRegister("lastName", {
              required: "Last name is required",
            })}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Title */}
      <div>
        <label>
          Title
          <input
            type="text"
            {...nominationFormRegister("title")}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="title"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Company */}
      <div>
        <label>
          Company
          <input
            type="text"
            {...nominationFormRegister("company")}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="company"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* City */}
      <div>
        <label>
          Guest city
          <input
            type="text"
            {...nominationFormRegister("city")}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="city"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* State */}
      <div>
        <label>
          Guest state
          <select
            {...nominationFormRegister("state")}
            disabled={disabled}
            defaultValue=""
          >
            <option value="">Select a state...</option>

            {states.map((state) => (
              <option key={state[0]} value={state[0]}>
                {state[1]}
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* Email */}
      <div>
        <label>
          Email*
          <input
            type="text"
            {...nominationFormRegister("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Email is not valid",
              },
            })}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Phone */}
      <div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, max-content)",
            gap: "5px",
            alignItems: "end",
          }}
        >
          <label>Mobile Number</label>

          <svg
            data-tip
            data-for="phone-field-tooltip"
            style={{ marginBottom: "4px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-question-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
          </svg>

          <ReactTooltip
            id="phone-field-tooltip"
            place="right"
            type="dark"
            effect="solid"
          >
            <div style={{ maxWidth: "300px" }}>
              By providing your mobile number, you are opting-in to receive text
              and/or sms messages about your event. Message and data rates may
              apply. If you would like to opt-out of receiving these messages,
              please reply STOP to the text message.
            </div>
          </ReactTooltip>
        </div>

        <div>
          <input
            type="text"
            {...nominationFormRegister("phone", {
              validate: (phone) => {
                if (phone) {
                  const validNumberRegex = /(\d){3}-(\d){3}-(\d){4}/;
                  const validNumber = validNumberRegex.test(phone);

                  if (!validNumber) {
                    const message =
                      'Mobile number has an invalid format. Please use "214-555-1212" format.';
                    return message;
                  }
                }
              },
            })}
            placeholder="214-555-1212"
            disabled={disabled}
          />

          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => <p className="error-msg">{message}</p>}
          />
        </div>
      </div>

      {/* Guest Type */}
      <div>
        <label>
          Guest Type
          <select
            {...nominationFormRegister("guestType")}
            disabled={disabled}
            defaultValue=""
          >
            <option value="">Select a guest type...</option>

            {guestTypes.map((guestType) => (
              <option key={guestType.value} value={guestType.value}>
                {guestType.name}
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* Guest Rep */}
      <div>
        <label>
          Guest Rep
          <select
            {...nominationFormRegister("guestRep")}
            disabled={disabled}
            defaultValue=""
          >
            <option value="">Select a guest rep...</option>

            {guestReps.map((guestRep) => (
              <option key={guestRep.id} value={guestRep.id}>
                {guestRep.name} ({guestRep.email})
              </option>
            ))}
          </select>
        </label>
      </div>

      {/* # of Tickets Needed */}
      <div>
        <div className="plus-ones-wrapper">
          <label># of Tickets Needed*</label>

          <svg
            data-tip
            data-for="number-of-plus-ones-field-tooltip"
            style={{ marginBottom: "4px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-question-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
          </svg>

          <ReactTooltip
            id="number-of-plus-ones-field-tooltip"
            place="right"
            type="dark"
            effect="solid"
          >
            <div style={{ maxWidth: "300px" }}>
              Enter the number of tickets you'll need for your party including
              yourself. If this field is locked, that means the host will only
              allow a certain number. Contact your host if you have questions.
            </div>
          </ReactTooltip>
        </div>

        <input
          type="text"
          {...nominationFormRegister("additionalTickets", {
            required: "# of Tickets Needed is required",
            valueAsNumber: true,
            min: {
              value: 1,
              message: "# of Ticket Needed must be greater than 0",
            },
            validate: (totalTickets) => {
              if (totalTickets <= event?.remainingSpots) {
                return null;
              } else if (isNaN(Number(totalTickets))) {
                return "Please input a valid number for the # of Tickets Needed.";
              }

              return `You are trying to register for more tickets than are available (${
                event?.remainingSpots
              } ${
                event?.remainingSpots === 1 ? "ticket" : "tickets"
              } remaining). Please edit your guest list or reach out to your event manager for assistance.`;
            },
          })}
          className={disabled ? "disabled-field" : ""}
          disabled={disabled}
        />

        <ErrorMessage
          errors={errors}
          name="additionalTickets"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Request Parking Pass */}
      <div className="request-pp-checkbox-wrapper">
        <label>
          Request Parking Pass
          <div className="checkbox-inner-wrapper">
            <input
              type="checkbox"
              {...nominationFormRegister("requestParkingPass")}
              id="request-pp-checkbox"
              disabled={disabled}
            />
            <span className="checkmark"></span>
          </div>
        </label>
      </div>

      {/* Current Annual Revenue */}
      <div>
        <label>
          Current Annual Revenue
          <input
            type="text"
            {...nominationFormRegister("currentAnnualRevenue", {
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Current annual revenue must be greater than 0",
              },
              validate: (revenue) => {
                if (isNaN(Number(revenue))) {
                  return "Please input a valid number for the guest's current annual revenue.";
                }
              },
            })}
            className={disabled ? "disabled-field" : ""}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="currentAnnualRevenue"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Opportunity Annual Revenue */}
      <div>
        <label>
          Opportunity Annual Revenue
          <input
            type="text"
            {...nominationFormRegister("opportunityAnnualRevenue", {
              valueAsNumber: true,
              min: {
                value: 0,
                message: "Opportunity annual revenue must be greater than 0",
              },
              validate: (revenue) => {
                if (isNaN(Number(revenue))) {
                  return "Please input a valid number for the guest's opportunity annual revenue.";
                }
              },
            })}
            className={disabled ? "disabled-field" : ""}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="opportunityAnnualRevenue"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* Company Website */}
      <div>
        <label>
          Guest Company Website
          <input
            type="text"
            {...nominationFormRegister("website")}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="website"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>

      {/* LinkedIn Profile URL */}
      <div>
        <label>
          Guest LinkedIn profile URL
          <input
            type="text"
            {...nominationFormRegister("linkedinProfileUrl")}
            disabled={disabled}
          />
        </label>

        <ErrorMessage
          errors={errors}
          name="linkedinProfileUrl"
          render={({ message }) => <p className="error-msg">{message}</p>}
        />
      </div>
    </div>
  );
};

export default NomineeDetailsForm;
