import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMousePointer, faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './sponsor-links.scss';

const SponsorLinks = props => {
	let sponsorLinks = () => {
		const possibleLinks = [
			{ name: 'website', icon: faMousePointer },
			{ name: 'facebook', icon: faFacebook },
			{ name: 'twitter', icon: faTwitter },
			{ name: 'instagram', icon: faInstagram },
			{ name: 'linkedin', icon: faLinkedin },
			{ name: 'virtual_link', icon: faLink },
		];
		const links = [];
		possibleLinks.forEach((link) => {
			let url = props.sponsor[link.name];
			if (url && url !== '') {
				link.url = url;
				links.push(link);
			}
		});
		return links;
	};
	const { onLinkClick } = props;
	const links = sponsorLinks();

	return (
		<ul className="s-links">
			{links.map((link, i) => {
				return (
					<li key={'s-link-' + i}>
						<a href={link.url} target='_blank'>
							<FontAwesomeIcon icon={link.icon} size='sm' onClick={onLinkClick}/>
							<span className="hidden">{link.name}</span>
						</a>
					</li>
				);
			})}
		</ul>
	);
};

export default SponsorLinks;
