// Library Imports
import React, { useState } from "react";

// Components

// Styling
import "./nav.scss";

const Nav = (props) => {
	const logo = props?.event?.navBar?.logo
		? props.event.navBar.logo
		: props?.logo;
	const backgroundColor = props?.event?.navBar?.backgroundColor
		? props.event.navBar.backgroundColor
		: props?.backgroundColor;
	const borderColor = props?.event?.navBar?.borderColor
		? props.event.navBar.borderColor
		: props?.borderColor;
	const linkColor = props?.event?.navBar?.linkColor
		? props.event.navBar.linkColor
		: props?.linkColor;

	const [isExpanded, setIsExpanded] = useState(false);

	const toggle = () => {
		setIsExpanded((s) => !s);
	};

	const closeNav = () => {
		setIsExpanded(false);
	};

	return (
		<nav
			id={props.id}
			className="nav alignfull"
			data-expanded={isExpanded ? "true" : "false"}
			style={{ backgroundColor, borderBottom: `1px solid ${borderColor}` }}
		>
			<div className="site-title">
				{logo && (
					<div className="nav-logo">
						<img src={logo} alt="" />
					</div>
				)}
			</div>
			<div className="links-wrapper">
				<ul className="links-list">
					{props.navLinks?.map((link, i) => {
						return (
							<li
								key={"navlink-" + i}
								style={{ borderBottom: `1px solid ${linkColor}` }}
							>
								<a
									onClick={closeNav}
									href={link.link}
									style={{ color: linkColor }}
								>
									{link.name}
								</a>
							</li>
						);
					})}
				</ul>
			</div>

			<button
				onClick={toggle}
				className="nav-toggle"
				style={{ color: linkColor, "&:hover": linkColor, "&:focus": linkColor }}
			>
				<div className="toggle-icon">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</button>
		</nav>
	);
};

export default Nav;
