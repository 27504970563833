import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faLink } from '@fortawesome/free-solid-svg-icons';
import useGetWindowDimensions from '../../hooks/use-get-window-dimensions';
import parse from 'html-react-parser';
import './resources.scss';


const Resources = props => {
	const { screenWidth } = useGetWindowDimensions();
	const centerResources = () => {
		const breakpoint = 550;
		let center = false;
		if (screenWidth <= breakpoint) {
			center = props.resources.length === 1;
		} else {
			center = props.resources.length <= 4;
		}
		return center.toString();
	};
	return (
		<div id={props.id} className="resources">
			<ul className="resource-list" center-resources={centerResources()}>
				{props.resources.map((resource, i) => {
					return <Resource key={'resource-' + i} resource={resource} />;
				})}
			</ul>
		</div>
	);
};

const Resource = props => {
  const { resource } = props;
  return (
		<li className="resource">
			{
        resource.embedded ? (
          <EmbeddedResource resource={resource} />
        ) : (
          <NonEmbeddedResource resource={resource} />
        )
      }
		</li>
	);
};

const NonEmbeddedResource = props => {
  const { resource } = props;
	return (
		<a className="resource-link card" href={resource.url} target="_blank" rel="noreferrer">
      <div>
        <FontAwesomeIcon icon={resourceIcon(resource.url)} size="3x" />
      </div>
      {resource.title}
    </a>
	);
};

const EmbeddedResource = props => {
  const { resource } = props;
  return parse(resource.iframe);
};

const resourceIcon = url => {
	let icon = null;
	if (url.includes('.pdf') || url.includes('.doc')) {
		icon = faFile;
	} else {
		icon = faLink;
	}
	return icon;
};

export default Resources;
