import Checkbox from "@mui/material/Checkbox";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Components
import AppButton from "../../shared/buttons/app-button/app-button";
import { Attendee } from "../../attendees/attendees";
import ConnectionStepSetting from "./ConnectionStepSetting";

const ConnectionStep = (props) => {
	const {
		hideBio,
		setHideBio,
		linkedInOAuthUrl,
		profilePictureUrl,
		inviteFormValues,
		inviteLandingPageUrl,
		stepsComponent,
		sendUpdateInvite,
	} = props;

	return (
		<div className="connection-step-container">
			<h2 className="heading">✓ You're all set!</h2>
			{stepsComponent.showConnectionStep && (
				<div className="subheading">
					Below are optional settings to bring your experience to the next
					level.
				</div>
			)}
			{stepsComponent.showConnectionStep ? (
				<div className="content">
					<div
						className="preview"
						style={{ paddingTop: hideBio ? "81px" : "54px" }}
					>
						<Attendee
							attendee={{
								name: `${inviteFormValues.firstName} ${inviteFormValues.lastName}`,
								title: inviteFormValues.title,
								company: inviteFormValues.company,
								firstName: inviteFormValues.firstName,
								lastName: inviteFormValues.lastName,
								linkedinProfileUrl: inviteFormValues.linkedinProfileUrl,
								image: profilePictureUrl,
							}}
							hideBio={hideBio}
							hideConnectWith={true}
						/>
					</div>

					<div className="settings">
						<ConnectionStepSetting number={1}>
							<div>Add your LinkedIn Photo</div>
							<AppButton
								className="linkedin-btn"
								size="small"
								href={linkedInOAuthUrl}
							>
								<LinkedInIcon fontSize={"small"} />
								Connect now
							</AppButton>
						</ConnectionStepSetting>

						<ConnectionStepSetting number={2}>
							<div>Hide your bio</div>
							<label className="hide-bio-label">
								Hide bio
								<Checkbox
									checked={hideBio}
									onChange={() => {
										setHideBio((prevValue) => {
											const newValue = !prevValue;
											sendUpdateInvite({ user: { is_vip: newValue } });
											return newValue;
										});
									}}
								/>
							</label>
						</ConnectionStepSetting>

						<ConnectionStepSetting number={3}>
							<div>Access Your Event Wallet for tickets, etc.</div>
							<div className="see-more-attendees-btn-wrapper">
								<a href={inviteLandingPageUrl} target="_blank" rel="noreferrer">
									Click Here
								</a>
							</div>
						</ConnectionStepSetting>
					</div>
				</div>
			) : (
				<div className="content">
					Please look for additional information in your inbox.
				</div>
			)}
		</div>
	);
};

export default ConnectionStep;
