// Library Imports
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

// Components
import AppLoader from "../../components/shared/loaders/app-loader";
import ErrorPage from "../error-page/error-page";
import Helmet from "../../components/shared/helmet/helmet";
import Nav from "../../components/nav/nav";
import EventSeriesHero from "../../components/event-series-hero/event-series-hero";
import Footer from "../../components/footer/footer";

const EventSeriesRegistration = () => {
	const queryParams = useParams();
	const { companyId, eventSeriesId } = queryParams;
	const [data, setData] = useState(undefined);
	const [axiosError, setAxiosError] = useState();

	useEffect(() => {
		const getData = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_BACKEND_HOST}/api/v1/companies/${companyId}/company_pages/${eventSeriesId}`
				);

				setData(response.data);
			} catch (error) {
				setAxiosError(error);
			}
		};

		getData();
	}, [companyId, eventSeriesId]);

	if (axiosError) {
		return <ErrorPage message="Event Series Page not found" />;
	}

	const navLinks = [
		{
			name: "Top",
			link: "#top",
		},
		{
			name: "Footer",
			link: "#footer",
		},
	];

	return (
		<main className="page">
			{!data ? (
				<AppLoader />
			) : (
				<>
					<Helmet
						title={`${data.event_series.title} | Event Series`}
						description={data.event_series.description}
						img={data.hero.backgroundImage}
					/>

					<Nav navLinks={navLinks} {...data.navBar} />

					<EventSeriesHero
						id="top"
						eventSeries={data.event_series}
						heroStyling={data.hero}
					/>

					<section className="alignfull">
						<Footer id="footer" {...data.footer} />
					</section>
				</>
			)}
		</main>
	);
};

export default EventSeriesRegistration;
