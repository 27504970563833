import { useState, useEffect } from "react";

import useGetQueryParams from "../../../hooks/use-get-query-params";
import useGet from "../../../hooks/use-get";

const useInviteData = (addQueryParams, backendHost) => {
	const [hideBio, setHideBio] = useState(false);

	const [inviteId, setInviteId] = useState(null);
	const [inviteRsvpStatus, setInviteRsvpStatus] = useState(null);
	const [inviteEmail, setInviteEmail] = useState(null);

	const [remainingPlusOnes, setRemainingPlusOnes] = useState(null);
	const [plusOnesStepEnabled, setPlusOnesStepEnabled] = useState(false);

	const [inviteHasCompletedWaiver, setInviteHasCompletedWaiver] =
		useState(false);
	const [inviteProfilePicture, setInviteProfilePicture] = useState(false);
	const [inviteLandingPageUrl, setInviteLandingPageUrl] = useState(null);
	const [inviteTier, setInviteTier] = useState({});
	const [inviteTicketAssigned, setInviteTicketAssigned] = useState(false);
	const [
		inviteMagicUrlForEventWalletModal,
		setInviteMagicUrlForEventWalletModal,
	] = useState(null);

	const queryParams = useGetQueryParams();
	const queryParamsInviteIdIsNumber = !isNaN(parseInt(queryParams.inviteId));
	const orderIdIsNumber = !isNaN(parseInt(queryParams.orderId));

	const [
		fetchInviteData,
		inviteDataResponse,
		inviteDataLoading,
		inviteDataError,
	] = useGet(`${backendHost}/api/v1/invites/${queryParams.inviteId}`);

	const _setInviteId = (id) => {
		setInviteId(id);
		addQueryParams({ inviteId: id });
	};

	useEffect(() => {
		if (queryParamsInviteIdIsNumber && !orderIdIsNumber) {
			_setInviteId(queryParams.inviteId);
			fetchInviteData({});
		}
	}, []);

	const inviteDataResponseSucceed =
		!!inviteDataResponse && !inviteDataError && !inviteDataLoading;

	const _setInviteTier = (newValues) =>
		setInviteTier({ ...inviteTier, ...newValues });

	useEffect(() => {
		if (!inviteDataResponse) {
			return;
		}

		setInviteEmail(inviteDataResponse.invitee.email);
		setInviteRsvpStatus(inviteDataResponse.rsvp_status);
		setRemainingPlusOnes(inviteDataResponse.plusOnesRemaining);
		setPlusOnesStepEnabled(inviteDataResponse.plusOnesPermitted);
		setInviteHasCompletedWaiver(inviteDataResponse.hasCompletedWaiver);
		setInviteProfilePicture(inviteDataResponse.linkedinProfilePictureUrl);
		setInviteLandingPageUrl(inviteDataResponse.landingPageUrl);
		setInviteTicketAssigned(inviteDataResponse.ticketAssigned);
		setInviteMagicUrlForEventWalletModal(
			inviteDataResponse.magicUrlForEventWalletModal
		);
	}, [inviteDataResponse]);

	return {
		hideBio,
		inviteId,
		inviteRsvpStatus,
		inviteEmail,
		remainingPlusOnes,
		plusOnesStepEnabled,
		inviteHasCompletedWaiver,
		inviteProfilePicture,
		inviteDataResponseSucceed,
		inviteDataResponse,
		inviteDataLoading,
		inviteLandingPageUrl,
		inviteTier,
		inviteTicketAssigned,
		inviteMagicUrlForEventWalletModal,
		setHideBio,
		setInviteId: _setInviteId,
		setInviteRsvpStatus,
		setInviteEmail,
		setRemainingPlusOnes,
		setPlusOnesStepEnabled,
		setInviteHasCompletedWaiver,
		setInviteProfilePicture,
		setInviteLandingPageUrl,
		setInviteTier: _setInviteTier,
		setInviteTicketAssigned,
		setInviteMagicUrlForEventWalletModal,
	};
};

export default useInviteData;
