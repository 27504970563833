// Library Imports
import React from "react";
import { backgroundStyle } from "../../lib/background";

// Components
import RegistrationFormContainer from "../registration-form-container/registration-form-container";
import CountdownTimer from "../countdown-timer/countdown-timer";

// Styling
import "./hero.scss";

const Hero = (props) => {
  const {
    showRegistrationForm = false,
    registrationStepFormFields,
    featuredSponsor,
    event,
    stepsComponent,
  } = props;

  return (
    <div id={props.id} className="hero alignfull">
      <div
        className="hero-background"
        style={backgroundStyle(props.backgroundImage, props.backgroundColor)}
      ></div>

      <div className="hero-content-wrapper alignwide">
        <div className="content-and-feature-container">
          <div className="hero-content">
            {props.logo && (
              <div className="event-logo">
                <img src={props.logo} alt="" />
              </div>
            )}

            <h2>{props.title}</h2>
            <h4>{props.subtitle}</h4>
            {props.countdownTimer && (
              <CountdownTimer startTime={event.dateTimeStart} />
            )}
            <h3>{event.dateTimeStart}</h3>
            <h4>{props.location}</h4>
          </div>

          {showRegistrationForm && (
            <div className="hero-feature">
              <RegistrationFormContainer
                registrationStepFormFields={registrationStepFormFields}
                event={event}
                stepsComponent={stepsComponent}
                featuredSponsor={featuredSponsor}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
