// Library Imports
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { ErrorMessage } from "@hookform/error-message";

// Components
import AppLoader from "../shared/loaders/app-loader";
import AppButton from "../shared/buttons/app-button/app-button";

// Hooks
import usePost from "../../hooks/use-post";
import useAppForm from "../../hooks/use-app-form";
import useGetConfigData from "../../hooks/use-get-config-data";
import useGetQueryParams from "../../hooks/use-get-query-params";

// Styling
import "./register-other-form.scss";

const RegisterOtherForm = (props) => {
	const {
		event: { guestReps, remainingSpots },
	} = props;

	const [inviteFormBackendErrorMsg, setInviteFormBackendErrorMsg] =
		useState(null);
	const [sendInviteFormSucceeded, setSendInviteFormSucceeded] = useState(null);
	const [successfulInviteeEmail, setSuccessfulInviteeEmail] = useState(null);
	const [inviteCounter, setInviteCounter] = useState(0);

	let isLoading = true;

	const { REACT_APP_BACKEND_HOST } = useGetConfigData();
	const { permalinkToken } = useParams();
	const queryParams = useGetQueryParams();
	const queryParamsInviteIdIsNumber = !isNaN(parseInt(queryParams.inviteId));

	const [
		sendInviteForm,
		inviteFormResponse,
		inviteFormLoading,
		inviteFormError,
	] = usePost(
		`${REACT_APP_BACKEND_HOST}/api/v1/guest/events/${permalinkToken}/invites`
	);
	const disabled = inviteFormLoading;

	const { register, handleSubmit, formState, setValue, watch } = useAppForm({
		mode: "all",
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			guestRep: "",
			additionalTickets: 0,
			status: "invited-other",
		},
	});

	const inviteFormRegister = register;
	const inviteFormState = formState;
	const setInviteFormValue = setValue;
	const inviteFormValues = watch();
	const errors = inviteFormState ? inviteFormState.errors : undefined;

	const onSubmit = (data) => {
		setInviteFormBackendErrorMsg(null);
		setSendInviteFormSucceeded(null);
		setSuccessfulInviteeEmail(null);

		sendInviteForm({
			user: {
				first_name: data.firstName,
				last_name: data.lastName,
				email: data.email,
				phone: data.phone,
				rep_id: data.guestRep,
				additional_tickets: data.additionalTickets,
				status: "invited-other",
			},
		});
	};
	const handleInviteFormSubmit = handleSubmit(onSubmit);

	const calculateCurrentInviteCount = () => {
		if (inviteFormValues) {
			let currentInviteCount = inviteCounter;
			currentInviteCount += inviteFormValues.additionalTickets + 1;

			return currentInviteCount;
		}
	};

	const resetInviteFormValues = () => {
		setInviteFormValue("firstName", "", { shouldValidate: false });
		setInviteFormValue("lastName", "", { shouldValidate: false });
		setInviteFormValue("email", "", { shouldValidate: false });
		setInviteFormValue("phone", "", { shouldValidate: false });
		setInviteFormValue("guestRep", "", { shouldValidate: false });
		setInviteFormValue("additionalTickets", 0, { shouldValidate: false });
	};

	useEffect(() => {
		if (inviteFormResponse) {
			const inviteeEmail = inviteFormResponse?.invitee.email;
			setSuccessfulInviteeEmail(inviteeEmail);

			const currentInviteCount = calculateCurrentInviteCount();
			setInviteCounter(currentInviteCount);

			setSendInviteFormSucceeded(true);
			setInviteFormBackendErrorMsg(null);
			resetInviteFormValues();
		}
	}, [inviteFormResponse]);

	useEffect(() => {
		if (inviteFormError) {
			setInviteFormBackendErrorMsg(inviteFormError.errors);
		}
	}, [inviteFormError]);

	const handleSubmitButtonClick = () => {
		handleInviteFormSubmit();
	};

	isLoading = false;

	return (
		<div className="registration-form-main-container register-other">
			{isLoading && (
				<div className="loader-wrapper">
					<AppLoader />
				</div>
			)}
			{!isLoading && (
				<div className="register-other-form">
					<div className="form-title">
						<h2>Invite Someone Else</h2>
					</div>

					{inviteFormBackendErrorMsg && (
						<p className="error-msg">{inviteFormBackendErrorMsg}</p>
					)}

					{sendInviteFormSucceeded && (
						<div className="form-submit-succeeded">
							<p className="form-submit-message">
								Successfully sent invite to {successfulInviteeEmail}
							</p>
						</div>
					)}

					<div className="form-row">
						<div>
							<label>
								First Name*
								<input
									type="text"
									{...inviteFormRegister("firstName", {
										required: "First name is required",
									})}
									disabled={disabled}
								/>
							</label>

							<ErrorMessage
								errors={errors}
								name="firstName"
								render={({ message }) => <p className="error-msg">{message}</p>}
							/>
						</div>
						<div>
							<label>
								Last Name*
								<input
									type="text"
									{...inviteFormRegister("lastName", {
										required: "Last name is required",
									})}
									disabled={disabled}
								/>
							</label>

							<ErrorMessage
								errors={errors}
								name="lastName"
								render={({ message }) => <p className="error-msg">{message}</p>}
							/>
						</div>
					</div>

					<div>
						<label>
							Email*
							<input
								type="text"
								{...inviteFormRegister("email", {
									required: "Email is required",
									pattern: {
										value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
										message: "Email is not valid",
									},
								})}
								disabled={disabled}
							/>
						</label>

						<ErrorMessage
							errors={errors}
							name="email"
							render={({ message }) => <p className="error-msg">{message}</p>}
						/>
					</div>

					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, max-content)",
							gap: "5px",
							alignItems: "end",
						}}
					>
						<label>Mobile Number</label>
						<svg
							data-tip
							data-for="phone-field-tooltip"
							style={{ marginBottom: "4px" }}
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-question-circle-fill"
							viewBox="0 0 16 16"
						>
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
						</svg>

						<ReactTooltip
							id="phone-field-tooltip"
							place="right"
							type="dark"
							effect="solid"
						>
							<div style={{ maxWidth: "300px" }}>
								By providing your mobile number, you are opting-in to receive
								text and/or sms messages about your event. Message and data
								rates may apply. If you would like to opt-out of receiving these
								messages, please reply STOP to the text message.
							</div>
						</ReactTooltip>
					</div>

					<div>
						<input
							type="text"
							{...register("phone", {
								validate: (phone) => {
									if (phone) {
										const validNumberRegex = /(\d){3}-(\d){3}-(\d){4}/;
										const validNumber = validNumberRegex.test(phone);

										if (!validNumber) {
											const message =
												'Mobile number has an invalid format. Please use "214-555-1212" format.';
											return message;
										}
									}
								},
							})}
							placeholder="214-555-1212"
							disabled={disabled}
						/>

						<ErrorMessage
							errors={errors}
							name="phone"
							render={({ message }) => <p className="error-msg">{message}</p>}
						/>
					</div>

					{guestReps?.length > 0 && (
						<div>
							<label>
								Guest Rep
								<select
									{...inviteFormRegister("guestRep")}
									disabled={disabled}
									defaultValue=""
								>
									<option value="">Select a guest rep...</option>
									{guestReps.map((guestRep) => (
										<option value={guestRep.id}>
											{guestRep.name} ({guestRep.email})
										</option>
									))}
								</select>
							</label>
						</div>
					)}

					<div>
						<div className="plus-ones-wrapper">
							<label># of Plus-Ones*</label>
							<svg
								data-tip
								data-for="number-of-plus-ones-field-tooltip"
								style={{ marginBottom: "4px" }}
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								fill="currentColor"
								className="bi bi-question-circle-fill"
								viewBox="0 0 16 16"
							>
								<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
							</svg>
							<ReactTooltip
								id="number-of-plus-ones-field-tooltip"
								place="right"
								type="dark"
								effect="solid"
							>
								<div style={{ maxWidth: "300px" }}>
									Enter the # of guests you'll bring with you. If this field is
									locked, that means the host will only allow a certain number.
									Contact your host if you have questions.
								</div>
							</ReactTooltip>
						</div>

						<input
							type="text"
							{...inviteFormRegister("additionalTickets", {
								required: "# of Plus-Ones is required",
								valueAsNumber: true,
								min: {
									value: 0,
									message:
										"Additional Tickets quantity must be greater than or equal to 0",
								},
								validate: (spotsForPlusOnes) => {
									const spotsForInvite = 1;
									if (queryParamsInviteIdIsNumber) return null;
									const spotsToBeUsedInRegistration =
										spotsForPlusOnes + spotsForInvite;
									if (spotsToBeUsedInRegistration <= remainingSpots)
										return null;
									return `You are trying to register for more tickets than are available (${remainingSpots} ${
										remainingSpots === 1 ? "ticket" : "tickets"
									} remaining). Please edit your guest list or reach out to your event manager for assistance.`;
								},
							})}
							className={disabled ? "disabled-field" : ""}
							disabled={disabled}
						/>

						<ErrorMessage
							errors={errors}
							name="additionalTickets"
							render={({ message }) => <p className="error-msg">{message}</p>}
						/>
					</div>

					{sendInviteFormSucceeded && inviteCounter && (
						<div className="invite-form-counter">
							<p>
								{inviteCounter}{" "}
								{inviteCounter > 1 ? "invites have" : "invite has"} been sent
							</p>
						</div>
					)}

					<div className="submit-button-wrapper">
						<AppButton
							className="submit-button"
							size="large"
							variant="contained"
							onClick={handleSubmitButtonClick}
						>
							Submit
						</AppButton>
					</div>
				</div>
			)}
		</div>
	);
};

export default RegisterOtherForm;
