import { useEffect } from "react";

import usePut from "../../../hooks/use-put";

const useUpdateInvite = (
  backendHost,
  inviteId,
  setInviteHasCompletedWaiver,
  setInviteRsvpStatus,
  setInviteTicketAssigned
) => {
  const [
    sendUpdateInvite,
    updateInviteData,
    updateInviteLoading,
    updateInvitError,
  ] = usePut(`${backendHost}/api/v1/invites/${inviteId}`);

  const updateInviteResponseSucceed =
    updateInviteData && !updateInvitError && !updateInviteLoading;

  useEffect(() => {
    if (!updateInviteResponseSucceed) return;
    setInviteHasCompletedWaiver(updateInviteData.hasCompletedWaiver);
    setInviteRsvpStatus(updateInviteData.rsvp_status);
    setInviteTicketAssigned(updateInviteData.ticketAssigned);
  }, [updateInviteResponseSucceed]);

  return { sendUpdateInvite, updateInviteLoading };
};

export default useUpdateInvite;
