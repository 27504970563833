import React from "react";
import "./attendees.scss";

export const Attendee = (props) => {
	const { attendee, hideBio = false, hideConnectWith = false } = props;
	const image =
		(!hideBio && attendee.image) ||
		`https://avatars.dicebear.com/api/initials/${attendee.name}.svg`.replace(
			/ /g,
			"%20"
		);
	return (
		<div className="attendee card">
			<div
				className="attendee-image"
				style={{ backgroundImage: `url(${image})` }}
			></div>
			<div className="attendee-details">
				<h3 className="attendee-name">
					{hideBio
						? `${attendee.firstName} ${attendee.lastName.substring(0, 1)}`
						: attendee.name}
				</h3>
				{!hideBio && (
					<h4 className="attendee-title">
						{attendee.title} | {attendee.company}
					</h4>
				)}
			</div>
			{!hideBio && !hideConnectWith && (
				<div className="attendee-action">
					<a href={attendee.contactLink || "#"} target="_blank">
						Connect with {attendee.firstName}
					</a>
				</div>
			)}
		</div>
	);
};

export const Attendees = (props) => {
	return (
		<section id={props.id} className="attendees">
			<ul className="attendee-list">
				{props.attendees.map((attendee, i) => {
					return (
						<li>
							<Attendee key={"attendee-" + i} attendee={attendee} />
						</li>
					);
				})}
			</ul>
		</section>
	);
};
