// Library Imports
import { createContext, useEffect, useCallback, useState } from "react";
import useGetQueryParams from "../hooks/use-get-query-params";

export const TicketingFormContext = createContext(null);

const TicketingFormContextProvider = ({ event, children }) => {
	const [step, setStep] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [totalPrice, setTotalPrice] = useState(0);
	const [appFee, setAppFee] = useState(0);

	const [formValidationError, setFormValidationError] = useState(null);
	const [selectedTickets, setSelectedTickets] = useState([]);

	const queryParams = useGetQueryParams();

	const checkPaymentQueryParams = () => {
		const internalParamPresent =
			queryParams["01110000"] && queryParams["01110000"] === "true";
		const paymentIntentPresent = !!queryParams["payment_intent"];
		const paymentIntentClientSecretPresent =
			!!queryParams["payment_intent_client_secret"];
		const redirectStatusPresent =
			queryParams["redirect_status"] &&
			queryParams["redirect_status"] === "succeeded";

		return (
			internalParamPresent &&
			paymentIntentPresent &&
			paymentIntentClientSecretPresent &&
			redirectStatusPresent
		);
	};

	const shouldRouteToPayment = checkPaymentQueryParams();

	const postData = useCallback(
		async (paymentIntentInfo) => {
			const inviteInfo = JSON.parse(localStorage.getItem("invite-info"));

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_HOST}/api/v1/guest/events/${event.permalinkToken}/paid_tickets`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						invite_info: {
							first_name: inviteInfo["first-name"],
							last_name: inviteInfo["last-name"],
							email: inviteInfo.email,
							mobile: inviteInfo.mobile,
							ticket_selection: inviteInfo["ticket-selection"],
						},
						order_info: {
							payment_intent_id: paymentIntentInfo.id,
							amount: paymentIntentInfo.amount,
						},
					}),
				}
			);

			if (response.ok) {
				// remove form information after successful API response
				localStorage.removeItem("invite-info");
			}
		},
		[event.permalinkToken]
	);

	useEffect(() => {
		if (shouldRouteToPayment) {
			// Send the user to the payment slide
			setStep(2);
		}
	}, [shouldRouteToPayment]);

	const formStepSubmission = () => {
		const submitButton = document.getElementById("personal-info-submit");

		try {
			const form = submitButton.closest("form");
			const formData = new FormData(form);

			validateForm(formData);
			submit(formData);
		} catch (error) {
			if (!error.ticketError && !error.mobileError) {
				// click submit button to show default form error messages
				submitButton.click();
			}
		}
	};

	const nextStep = () => {
		// if clicking next on personal info form step
		if (step === 1) {
			formStepSubmission();
		} else {
			setStep((step) => ++step);
		}
	};

	const previousStep = () => {
		setStep((step) => --step);
	};

	const handleTicketChange = (e) => {
		resetFormValidationError();

		setSelectedTickets((prev) => {
			const newVal = [...prev];

			if (newVal.includes(e.target.value)) {
				const itemIdx = newVal.indexOf(e.target.value);
				newVal.splice(itemIdx, 1);
			} else {
				newVal.push(e.target.value);
			}

			setTotalPrice(newVal.length * pricePerTicket());

			return newVal;
		});
	};

	const pricePerTicket = () => {
		return event.paidProduct.value;
	};

	const resetFormValidationError = () => {
		setFormValidationError(null);
	};

	const validateMobile = (mobile) => {
		const regex = /(\d){3}-(\d){3}-(\d){4}/;
		return regex.test(mobile);
	};

	const validateForm = (formData) => {
		const formDataKeys = ["email", "first-name", "last-name", "mobile"];

		for (const key of formDataKeys) {
			if (!formData.has(key) || formData.get(key) === "") {
				throw new Error();
			}
		}

		const phoneNumberIsValid = validateMobile(formData.get("mobile"));

		if (!phoneNumberIsValid) {
			setFormValidationError(
				'Mobile number has an invalid format. Please use "214-555-1212" format.'
			);

			const error = new Error();
			error.mobileError = true;
			throw error;
		}

		if (!selectedTickets || selectedTickets.length === 0) {
			setFormValidationError(
				"A ticket selection is required before continuing."
			);

			// need a way to skip submit on the current render
			// set state will update the next render, so our
			// try catch won't work as expected without this
			const error = new Error();
			error.ticketError = true;
			throw error;
		}

		// make sure that we have the right ticket selection in form data
		formData.append("ticket-selection", selectedTickets);
	};

	const submit = async (formData) => {
		setIsLoading(true);

		try {
			const submissionData = Object.fromEntries(formData);

			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_HOST}/events/${event.id}/stripe_payment_intent`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(submissionData),
				}
			);

			if (response.ok) {
				const data = await response.json();

				localStorage.setItem("invite-info", JSON.stringify(submissionData));
				localStorage.setItem("scs", data.body.clientSecret);

				setTotalPrice(data.body.orderAmount);
				setAppFee(data.body.appFee);
				setStep((step) => ++step);
			} else {
				setFormValidationError(
					"Form submission was unsuccessful. Please try again or contact an admin."
				);
			}
		} catch (error) {
			setFormValidationError(
				"Form submission was unsuccessful. Please try again or contact an admin."
			);
		}

		setIsLoading(false);
	};

	const provide = {
		event,
		step,
		nextStep,
		previousStep,
		isLoading,
		formValidationError,
		selectedTickets,
		handleTicketChange,
		totalPrice,
		appFee,
		postData,
	};

	return (
		<TicketingFormContext.Provider value={provide}>
			{children}
		</TicketingFormContext.Provider>
	);
};

export default TicketingFormContextProvider;
