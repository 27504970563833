import { React } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import useGetConfigData from '../../../hooks/use-get-config-data';
import AppLoader from '../../../components/shared/loaders/app-loader';
import './app-map.scss';


const AppMap = props => {
  const { REACT_APP_GOOGLE_MAPS_API_KEY } = useGetConfigData();
  const { isLoaded } = useLoadScript({ googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY });
  const { defaultCenter, defaultZoom=10, markers=[] } = props;

  if (!isLoaded) return <AppLoader />;
  return (
    <GoogleMap
      zoom={defaultZoom}
      center={{ lat: defaultCenter.latitude, lng: defaultCenter.longitude }}
      mapContainerClassName='app-map-container'
    >
      {
        markers.map( (marker, index) => {
          return (
            <Marker key={index} position={{ lat: marker.latitude, lng: marker.longitude }}/>
          );
        })
      }
    </GoogleMap>
  );
};

export default AppMap;