import { React } from 'react';
import './color-dot.scss';

const ColorDot = props => {
  const { radius, color } = props;
  const style = {
    height: `${radius || 10}px`,
    width: `${radius || 10}px`,
    backgroundColor: color || 'black'
  };
  return (
    <div className="color-dot" style={style}></div>
  );
};

export default ColorDot;