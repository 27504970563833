// Library Imports
import { useContext, useState, useCallback, useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";

// Components
import { TicketingFormContext } from "../../contexts/ticketing";
import AppLoader from "../shared/loaders/app-loader";
import TotalPrice from "./total-price";
import CheckoutForm from "./checkout-form";

// Styling
import "./payment-step.scss";

const PaymentStep = () => {
	const { nextStep, postData } = useContext(TicketingFormContext);
	const stripe = useStripe();
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState(null);

	const postDataAndIncrementStep = useCallback(
		async (paymentIntentData) => {
			setIsLoading(true);
			await postData(paymentIntentData);
			nextStep();
			setIsLoading(false);
		},
		[postData, nextStep]
	);

	const paymentSubmittedIntent = new URLSearchParams(
		window.Location.search
	).get("payment_intent_client_secret");
	const clientSecret = paymentSubmittedIntent ?? localStorage.getItem("scs");

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					setMessage("Payment succeeded!");
					postDataAndIncrementStep(paymentIntent);
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					setMessage("Your payment was not successful, please try again.");
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		});
	}, [stripe, postDataAndIncrementStep]);

	return (
		<>
			<h2 className="payment-step-title">Payment Details</h2>

			{/* Show any error or success messages */}
			{message && <div id="intent-message">{message}</div>}

			{isLoading ? (
				<div className="payment-step-loader">
					<AppLoader size={48} />
				</div>
			) : (
				<>
					{clientSecret && <CheckoutForm />}

					{/* <div className="price-breakdown">
						<h3>Price Breakdown</h3>
						<p className="ticket-price">
							Ticket Price
							<span>{currencyFormatter.format(totalPrice - appFee)}</span>
						</p>
						<p className="app-fee">
							App Fee
							<span>{currencyFormatter.format(appFee)}</span>
						</p>
					</div> */}
					<TotalPrice />
				</>
			)}
		</>
	);
};

export default PaymentStep;
