import { useEffect, useState } from 'react';
import axios from 'axios';

const useGet = url => {
  const [params, setParams] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    async () => {
      if (params) {
        try {
          setLoading(true);
          const response = await axios.get(url, params);
          setData(response.data);
          setError(null);
        } catch(err) {
          setError(err.response.data);
          setData(null);
        } finally {
          setLoading(false);
          setParams(null);
        }
      }
    }, [params]
  );

  return [setParams, data, loading, error];
}
export default useGet;