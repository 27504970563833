const ConnectionStepSetting = (props) => {
  const { number, children } = props;
  return (
    <div className="setting">
      <div className="number">{number}</div>
      <div className="setting-content">{children}</div>
    </div>
  );
};

export default ConnectionStepSetting;
