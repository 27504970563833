// Library Imports
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

// Components
import ArrowUpRightFromSquare from "../../img/arrow-up-right-from-square.svg";
import NominationsForm from "./nominations-form";

// Contexts
import NominationFormContextProvider from "../../contexts/nomination-form";

// Hooks
import useGetQueryParams from "../../hooks/use-get-query-params";

// Styling
import "./registration-and-nomination.scss";

const RegistrationAndNomination = ({
  event,
  customFields,
  guestTypes,
  guestReps,
}) => {
  const [open, setOpen] = useState(false);
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const queryParams = useGetQueryParams();
  const { hep, hrp, hbp } = queryParams;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85vw",
    height: "90vh",
    bgcolor: "background.paper",
    boxShadow: 8,
    p: 4,
  };

  return (
    <div className="registration-links-wrapper">
      {(hep || hrp) && (
        <button
          className="button button-primary register-link"
          onClick={openModal}
        >
          Nominate
        </button>
      )}

      {!hbp && !hrp && (
        <a
          className="button button-primary register-link"
          href={`/registration/${event.permalinkToken}`}
          target="_blank"
          rel="noreferrer"
        >
          Register
          <img
            src={ArrowUpRightFromSquare}
            className="fontawesome-icon"
            alt="square with an arrow starting at the center and traveling up and to the right"
          ></img>
        </a>
      )}

      {hep && (
        <a
          className="button button-primary register-link"
          href={`/registration/${event.permalinkToken}?rse=true`}
          target="_blank"
          rel="noreferrer"
        >
          Register someone else
          <img
            src={ArrowUpRightFromSquare}
            className="fontawesome-icon"
            alt="square with an arrow starting at the center and traveling up and to the right"
          ></img>
        </a>
      )}

      {hbp && (
        <a
          className="button button-primary register-link"
          href={`/ticketing/${event.permalinkToken}`}
          target="_blank"
          rel="noreferrer"
        >
          Purchase
          <img
            src={ArrowUpRightFromSquare}
            className="fontawesome-icon"
            alt="square with an arrow starting at the center and traveling up and to the right"
          ></img>
        </a>
      )}

      <Modal
        open={open}
        onClose={closeModal}
        closeAfterTransition
        aria-labelledby="nominate-someone-modal-title"
        aria-describedby="nominate-someone-modal-description"
      >
        <Fade in={open}>
          <Box sx={style}>
            <NominationFormContextProvider
              event={event}
              customFields={customFields}
              guestTypes={guestTypes}
              guestReps={guestReps}
            >
              <div id="nominations-form-modal">
                <button
                  className="button button-secondary modal-close-button"
                  onClick={closeModal}
                >
                  <CloseIcon />
                </button>

                <NominationsForm />
              </div>
            </NominationFormContextProvider>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default RegistrationAndNomination;
