// Library Imports
import { createContext, useState, useEffect, useCallback } from "react";
import axios from "axios";

// Hooks
import { useParams } from "react-router-dom";
import useGetQueryParams from "../hooks/use-get-query-params";
import usePostFormData from "../hooks/use-post-form-data";
import useAppForm from "../hooks/use-app-form";

export const NominationFormContext = createContext(null);

const NominationFormContextProvider = ({
  event,
  customFields,
  guestTypes,
  guestReps,
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [defaultFormValues, setDefaultFormValues] = useState({});
  const [multiselectValues, setMultiselectValues] = useState({});
  const [fileValues, setFileValues] = useState({});
  const [nominationFormBackendErrorMsg, setNominationFormBackendErrorMsg] =
    useState(null);
  const [sendNominationFormSucceeded, setSendNominationFormSucceeded] =
    useState(null);
  const [successfulNominationResponse, setSuccessfulNominationResponse] =
    useState(null);
  const [nominees, setNominees] = useState(null);
  const [states, setStates] = useState([]);

  const urlParams = useParams();
  const { companyId, eventSeriesId } = urlParams;

  const queryParams = useGetQueryParams();
  const { hep } = queryParams;

  const getNominees = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_BACKEND_HOST}/api/v1/companies/${companyId}/company_pages/${eventSeriesId}/events/${event.id}`
    );

    setNominees(result.data.numberOfNominees);
    setStates(result.data.states);
    setLoading(false);
  };

  useEffect(() => {
    getNominees();
  }, [companyId, eventSeriesId, event.id]);

  const resetDefaultValues = () => {
    for (const customField of customFields) {
      if (customField.field_type === "multi") {
        setMultiselectValues((prev) => ({
          ...prev,
          [customField.id]: [],
        }));
      } else {
        setDefaultFormValues((prev) => ({
          ...prev,
          [customField.id]: "",
        }));
      }
    }
  };

  useEffect(() => {
    resetDefaultValues();
  }, []);

  const [
    sendNominationForm,
    nominationFormResponse,
    nominationFormLoading,
    nominationFormError,
  ] = usePostFormData();
  const disabled = nominationFormLoading;

  const nominationFormDefaultValues = {
    firstName: "",
    lastName: "",
    title: "",
    company: "",
    email: "",
    phone: "",
    guestType: "",
    guestRep: "",
    additionalTickets: 1,
    companyWebsite: "",
    currentAnnualRevenue: 0,
    opportunityAnnualRevenue: 0,
    requestParkingPass: false,
    city: "",
    state: "",
    linkedinProfileUrl: "",
  };

  for (const [key, value] of Object.entries(defaultFormValues)) {
    nominationFormDefaultValues[key] = value;
  }

  const { register, formState, setValue, watch } = useAppForm({
    mode: "all",
    defaultValues: nominationFormDefaultValues,
  });
  const nominationFormValues = watch();

  const nominationFormRegister = register;
  const nominationFormState = formState;
  const setNominationFormValue = setValue;
  const errors = nominationFormState?.errors;

  const addKeyToFormData = (formData, key, data) => {
    const addRecursively =
      !(data instanceof File) && (data === Object(data) || Array.isArray(data));

    if (addRecursively) {
      for (var i in data) {
        if (Array.isArray(data)) {
          addKeyToFormData(formData, key + "[]", data[i]);
        } else {
          addKeyToFormData(formData, key + "[" + i + "]", data[i]);
        }
      }
    } else {
      formData.append(key, data);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    setNominationFormBackendErrorMsg(null);
    setSendNominationFormSucceeded(null);
    setSuccessfulNominationResponse(null);

    const formData = new FormData();

    const userObj = {
      first_name: nominationFormValues.firstName,
      last_name: nominationFormValues.lastName,
      title: nominationFormValues.title,
      company: nominationFormValues.company,
      email: nominationFormValues.email,
      phone: nominationFormValues.phone,
      guest_type: nominationFormValues.guestType,
      rep_id: nominationFormValues.guestRep,
      additional_tickets: nominationFormValues.additionalTickets - 1,
      company_website: nominationFormValues.website,
      current_annual_revenue: nominationFormValues.currentAnnualRevenue,
      opportunity_annual_revenue: nominationFormValues.opportunityAnnualRevenue,
      request_parking_pass: nominationFormValues.requestParkingPass,
      status: "nominated",
      city: nominationFormValues.city,
      state: nominationFormValues.state,
      linkedin_profile_url: nominationFormValues.linkedinProfileUrl,
      custom_fields: {},
    };

    for (const [key, value] of Object.entries(nominationFormValues)) {
      // custom field keys are the field id's, for tracking purposes
      if (Number(key) && value && value !== "") {
        if (value instanceof FileList) {
          continue;
        }

        userObj.custom_fields[key] = value;
      }
    }

    for (const [key, value] of Object.entries(multiselectValues)) {
      if (value.length > 0) {
        userObj.custom_fields[key] = value;
      }
    }

    for (const [key, value] of Object.entries(fileValues)) {
      if (value.length > 0) {
        userObj.custom_fields[key] = value;
      }
    }

    addKeyToFormData(formData, "user", userObj);

    const requestParams = {
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/api/v1/guest/events/${event.permalinkToken}/invites-custom-fields`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    sendNominationForm(requestParams);
  };

  const resetNominationFormValues = useCallback(() => {
    for (const key of Object.keys(nominationFormValues)) {
      if (key === "additionalTickets") {
        setNominationFormValue(key, 1, { shouldValidate: false });
      } else if (
        key === "currentAnnualRevenue" ||
        key === "opportunityAnnualRevenue"
      ) {
        setNominationFormValue(key, 0, { shouldValidate: false });
      } else {
        setNominationFormValue(key, "", { shouldValidate: false });
      }
    }

    for (const [_, value] of Object.entries(customFields)) {
      setNominationFormValue(String(value.id), "", { shouldValidate: false });
    }
  }, [setNominationFormValue]);

  useEffect(() => {
    if (nominationFormResponse) {
      const fieldAnswers = nominationFormResponse;

      if (fieldAnswers.length > 0) {
        const fieldAnswer = fieldAnswers[0];
        setSuccessfulNominationResponse(fieldAnswer);
      }

      getNominees();
      setSendNominationFormSucceeded(true);
      setNominationFormBackendErrorMsg(null);
      resetNominationFormValues();
      setLoading(false);
    }
  }, [nominationFormResponse]);

  useEffect(() => {
    if (nominationFormError) {
      setNominationFormBackendErrorMsg(nominationFormError.errors);
      setLoading(false);
    }
  }, [nominationFormError]);

  const handleSubmitButtonClick = () => {
    onSubmit();
  };

  const resetFormEntirely = () => {
    setLoading(true);
    resetDefaultValues();
    setFileValues({});
    setNominationFormBackendErrorMsg(null);
    setSendNominationFormSucceeded(null);
    setSuccessfulNominationResponse(null);
    setLoading(false);
  };

  const provide = {
    event,
    loading,
    customFields,
    guestTypes,
    guestReps,
    multiselectValues,
    setMultiselectValues,
    setFileValues,
    nominees,
    hep,
    nominationFormBackendErrorMsg,
    sendNominationFormSucceeded,
    successfulNominationResponse,
    nominationFormRegister,
    nominationFormValues,
    disabled,
    errors,
    handleSubmitButtonClick,
    resetFormEntirely,
    states,
  };

  return (
    <NominationFormContext.Provider value={provide}>
      {children}
    </NominationFormContext.Provider>
  );
};

export default NominationFormContextProvider;
