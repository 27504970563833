import React, { useState, useEffect } from "react";
import "./countdown-timer.scss";

const CountDownTimer = ({ startTime }) => {
	const [days, setDays] = useState();
	const [hours, setHours] = useState();
	const [minutes, setMins] = useState();
	const [seconds, setSeconds] = useState();

	const launchDate = new Date(startTime).getTime();
	const now = new Date().getTime();
	const distance = launchDate - now;

	const eventStarted = distance < 0;

	// Update every second if event hasn't started yet
	useEffect(() => {
		let interval;

		if (!eventStarted) {
			interval = setInterval(() => {
				const now = new Date().getTime();
				const distance = launchDate - now;

				// Time calculation
				setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
				setHours(
					Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				);
				setMins(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
				setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [eventStarted, launchDate, distance]);

	return (
		<div className="countdown-timer">
			{eventStarted ? (
				<h1>Event Started</h1>
			) : (
				<ul className="counter">
					<li>
						<div className="number">{days}</div>
						<div className="label">Days</div>
					</li>
					<li>
						<div className="number">{hours}</div>
						<div className="label">Hours</div>
					</li>
					<li>
						<div className="number">{minutes}</div>
						<div className="label">Minutes</div>
					</li>
					<li>
						<div className="number">{seconds}</div>
						<div className="label">Seconds</div>
					</li>
				</ul>
			)}
		</div>
	);
};
export default CountDownTimer;
