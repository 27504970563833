// Library Imports
import { useState, useContext, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Components
import AppLoader from "../shared/loaders/app-loader";
import NomineeDetailsForm from "./nominee-details-form";
import CustomFieldsForm from "./custom-fields-form";
import ConfirmationStep from "./confirmation-step";

// Contexts
import { NominationFormContext } from "../../contexts/nomination-form";

// Assets
import BlueArrowUpRightFromSquare from "../../img/blue-arrow-up-right-from-square.svg";

// Styling
import "./nominations-form.scss";

const NominationsForm = () => {
	const [nextDisabled, setNextDisabled] = useState(true);
	const [submitDisabled, setSubmitDisabled] = useState(true);
	const [activeStep, setActiveStep] = useState(0);

	const {
		event,
		customFields,
		loading,
		nominees,
		hep,
		handleSubmitButtonClick,
		multiselectValues,
		nominationFormValues,
		resetFormEntirely,
	} = useContext(NominationFormContext);

	const customFieldsFormValid = () => {
		const requiredFields = customFields.filter((field) => field.required);

		for (const requiredField of requiredFields) {
			if (
				(nominationFormValues[requiredField.id] &&
					nominationFormValues[requiredField.id] !== "") ||
				(!nominationFormValues[requiredField.id] &&
					multiselectValues[requiredField.id]?.length > 0)
			) {
				continue;
			} else {
				return false;
			}
		}

		return true;
	};

	useEffect(() => {
		if (activeStep === 1 && customFieldsFormValid()) {
			setSubmitDisabled(false);
		} else if (submitDisabled === false) {
			setSubmitDisabled(true);
		}
	}, [activeStep]);

	useEffect(() => {
		const nomineeDetailsFormValid = () => {
			const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
			const mobileRegex = /(\d){3}-(\d){3}-(\d){4}/;

			if (
				nominationFormValues.phone &&
				nominationFormValues.phone !== "" &&
				!mobileRegex.test(nominationFormValues.phone)
			) {
				return false;
			}

			return (
				nominationFormValues.firstName &&
				nominationFormValues.firstName !== "" &&
				nominationFormValues.lastName &&
				nominationFormValues.lastName !== "" &&
				nominationFormValues.email &&
				emailRegex.test(nominationFormValues.email) &&
				nominationFormValues.additionalTickets &&
				nominationFormValues.additionalTickets !== ""
			);
		};

		if (activeStep === 0 && nomineeDetailsFormValid()) {
			setNextDisabled(false);
		} else if (nextDisabled === false) {
			setNextDisabled(true);
		}

		if (activeStep === 1 && customFieldsFormValid()) {
			setSubmitDisabled(false);
		} else if (submitDisabled === false) {
			setSubmitDisabled(true);
		}
	}, [nominationFormValues]);

	const handleNext = () => {
		setActiveStep((prev) => ++prev);
	};

	const handleBack = () => {
		setActiveStep((prev) => --prev);
	};

	const handleSubmit = () => {
		handleNext();
		handleSubmitButtonClick();
	};

	const handleNominateAnotherPerson = () => {
		setActiveStep(0);
		resetFormEntirely();
	};

	return (
		<div className="nominations-form-wrapper">
			{loading ? (
				<div className="app-loader-wrapper">
					<AppLoader />
				</div>
			) : (
				<div className="nomination-modal">
					<Stepper activeStep={activeStep}>
						<Step>
							<StepLabel>Nominee Info</StepLabel>
						</Step>
						<Step>
							<StepLabel>Custom Fields</StepLabel>
						</Step>
						<Step>
							<StepLabel>Confirmation</StepLabel>
						</Step>
					</Stepper>

					<div className="nominations-details">
						<p className="number-of-nominees">
							{nominees} {nominees === 1 ? "person" : "people"} nominated
						</p>

						{hep && (
							<a
								className="manifest-link"
								href={`${process.env.REACT_APP_BACKEND_HOST}/events/${event.id}/manifest/${event.token}`}
								target="_blank"
								rel="noreferrer"
							>
								See who has been nominated for this event
								<img
									src={BlueArrowUpRightFromSquare}
									className="fontawesome-icon"
									alt="square with an arrow starting at the center and traveling up and to the right"
								></img>
							</a>
						)}
					</div>

					{activeStep === 0 && <NomineeDetailsForm />}
					{activeStep === 1 && <CustomFieldsForm />}
					{activeStep === 2 && <ConfirmationStep />}

					<div className="stepper-controls">
						{activeStep === 0 && (
							<>
								<div></div>
								<button
									className="button button-primary"
									onClick={handleNext}
									disabled={nextDisabled}
								>
									Next
								</button>
							</>
						)}

						{activeStep === 1 && (
							<>
								<button
									className="button button-secondary"
									onClick={handleBack}
								>
									Back
								</button>
								<button
									className="button button-primary submit-button"
									onClick={handleSubmit}
									disabled={submitDisabled}
								>
									Submit
								</button>
							</>
						)}

						{activeStep === 2 && (
							<>
								<div></div>
								<button
									className="button button-primary nominate-another-person-button"
									onClick={handleNominateAnotherPerson}
								>
									Nominate another person
								</button>
							</>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default NominationsForm;
