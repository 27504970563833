import React, { useState } from 'react';
import SponsorLinks from '../sponsor-links/sponsor-links';
import './sponsor-card.scss';
import ColorDot from '../shared/dots/color-dot';
import usePost from '../../hooks/use-post';
import useGetConfigData from '../../hooks/use-get-config-data';
import { useParams } from 'react-router-dom';
import useGetQueryParams from '../../hooks/use-get-query-params';

const SponsorCard = props => {
	const [linksVisited, setLinksVisited] = useState(false);
	const configData = useGetConfigData();
	const { permalinkToken } = useParams();
	const { REACT_APP_BACKEND_HOST } = configData;
	const { inviteId } = useGetQueryParams();
	const [createPunch] = usePost(
		`${REACT_APP_BACKEND_HOST}/api/v1/invites/${inviteId}/events/${permalinkToken}/engage/groups/${props.sponsor.id}/track`
	);
	let sponsorCardClassName = 'sponsor-card card';
	if (props.className) sponsorCardClassName += ` ${props.className}`;

	return (
		<div className={sponsorCardClassName}>
			<div className="sponsor-image">
				<img src={props.sponsor.logo} alt={props.sponsor.name + ' Logo'} />
			</div>
			<div className="card-header">
				<div className="label">Sponsored By</div>
				<ColorDot radius={10} color={(props.sponsor.visited || linksVisited) ? '#03608D' : '#bbb'} />
			</div>
			<h4 className="sponsor-name">{props.sponsor.name}</h4>
			<SponsorLinks
				sponsor={props.sponsor}
				onLinkClick={() => {
					createPunch({});
					setLinksVisited(true);
				}}
			/>
		</div>
	);
};

export default SponsorCard;
