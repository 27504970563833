import { ErrorMessage } from "@hookform/error-message";
import ReactTooltip from "react-tooltip";
import _isEmpty from "lodash/isEmpty";

import useGetQueryParams from "../../../hooks/use-get-query-params";

const InviteFormStep = (props) => {
	const {
		fields,
		register,
		tiers,
		disabled,
		backendErrorMsg,
		formState: { errors },
		event: { remainingSpots },
		additionalTicketsFieldEnabled,
		guestRepId,
		inviteFormValues,
		atfrnpConstant,
	} = props;
	const queryParams = useGetQueryParams();
	const queryParamsInviteIdIsNumber = !isNaN(parseInt(queryParams.inviteId));

	return (
		<div className="invite-form-step-container">
			<div className="form-title">
				<h2>Register Yourself</h2>

				{queryParamsInviteIdIsNumber && (
					<div
						className="not-you-link"
						onClick={() => {
							const domain = window.location.href.split("?")[0];
							let qParams = `atfrnp=${
								inviteFormValues.additionalTickets - atfrnpConstant
							}&prevInviteId=${queryParams.inviteId}`;
							if (guestRepId) qParams += `&repId=${guestRepId}`;
							window.location.href = `${domain}?${qParams}`;
						}}
					>
						Not you? Register Here
					</div>
				)}
			</div>

			{backendErrorMsg && <p className="error-msg">{backendErrorMsg}</p>}

			<div className="form-row">
				<div>
					<label>
						First Name*
						<input
							type="text"
							{...register("firstName", { required: "First name is required" })}
							disabled={disabled}
						/>
					</label>

					<ErrorMessage
						errors={errors}
						name="firstName"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<div>
					<label>
						Last Name*
						<input
							type="text"
							{...register("lastName", { required: "Last name is required" })}
							disabled={disabled}
						/>
					</label>

					<ErrorMessage
						errors={errors}
						name="lastName"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>
			</div>

			<div className="form-row">
				<div>
					<label>
						Title
						<input type="text" {...register("title")} disabled={disabled} />
					</label>

					<ErrorMessage
						errors={errors}
						name="title"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>

				<div>
					<label>
						Company
						<input type="text" {...register("company")} disabled={disabled} />
					</label>

					<ErrorMessage
						errors={errors}
						name="company"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>
			</div>

			{fields.address && (
				<label>
					Address
					<input type="text" {...register("address")} disabled={disabled} />
				</label>
			)}

			{fields.city && (
				<label>
					City
					<input type="text" {...register("city")} disabled={disabled} />
				</label>
			)}

			<div className="form-row">
				{fields.state && (
					<div>
						<label>
							State
							<input type="text" {...register("state")} disabled={disabled} />
						</label>
					</div>
				)}

				{fields.zip && (
					<div>
						<label>
							Zip
							<input
								type="text"
								{...register("zip", {
									pattern: {
										value: /^([0-9]{5})$/,
										message: "Zip is not valid",
									},
								})}
								disabled={disabled}
							/>
						</label>

						<ErrorMessage
							errors={errors}
							name="zip"
							render={({ message }) => <p className="error-msg">{message}</p>}
						/>
					</div>
				)}
			</div>

			<label>
				Email*
				<input
					type="text"
					{...register("email", {
						required: "Email is required",
						pattern: {
							value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
							message: "Email is not valid",
						},
					})}
					disabled={disabled}
				/>
			</label>

			<ErrorMessage
				errors={errors}
				name="email"
				render={({ message }) => <p className="error-msg">{message}</p>}
			/>

			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(2, max-content)",
					gap: "5px",
					alignItems: "end",
				}}
			>
				<label>Mobile Number{fields.requireMobileNumber && "*"}</label>
				<svg
					data-tip
					data-for="phone-field-tooltip"
					style={{ marginBottom: "4px" }}
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					fill="currentColor"
					className="bi bi-question-circle-fill"
					viewBox="0 0 16 16"
				>
					<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
				</svg>

				<ReactTooltip
					id="phone-field-tooltip"
					place="right"
					type="dark"
					effect="solid"
				>
					<div style={{ maxWidth: "300px" }}>
						By providing your mobile number, you are opting-in to receive text
						and/or sms messages about your event. Message and data rates may
						apply. If you would like to opt-out of receiving these messages,
						please reply STOP to the text message.
					</div>
				</ReactTooltip>
			</div>

			<div>
				<input
					type="text"
					{...register("phone", {
						validate: (phone) => {
							if (!phone) {
								return fields.requireMobileNumber
									? "Mobile number is required"
									: null;
							}

							const validNumberRegex = /(\d){3}-(\d){3}-(\d){4}/;
							const validNumber = validNumberRegex.test(phone);

							if (!validNumber) {
								const message =
									'Mobile number has an invalid format. Please use "214-555-1212" format.';
								return message;
							}
						},
					})}
					placeholder="214-555-1212"
					disabled={disabled}
				/>

				<ErrorMessage
					errors={errors}
					name="phone"
					render={({ message }) => <p className="error-msg">{message}</p>}
				/>
			</div>

			{fields.ticket && !_isEmpty(tiers) && (
				<label>
					Select One
					<select
						{...register("tier")}
						disabled={disabled || tiers.length === 1}
					>
						{tiers.map((tier) => {
							return (
								<option key={tier.id} value={tier.id}>
									{tier.title}
								</option>
							);
						})}
					</select>
				</label>
			)}

			{fields.ticket && (
				<div>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "repeat(2, max-content)",
							gap: "5px",
							alignItems: "end",
						}}
					>
						<label># of Plus-Ones*</label>
						<svg
							data-tip
							data-for="number-of-plus-ones-field-tooltip"
							style={{ marginBottom: "4px" }}
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-question-circle-fill"
							viewBox="0 0 16 16"
						>
							<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
						</svg>

						<ReactTooltip
							id="number-of-plus-ones-field-tooltip"
							place="right"
							type="dark"
							effect="solid"
						>
							<div style={{ maxWidth: "300px" }}>
								Enter the # of guests you'll bring with you. If this field is
								locked, that means the host will only allow a certain number.
								Contact your host if you have questions.
							</div>
						</ReactTooltip>
					</div>

					<input
						type="text"
						{...register("additionalTickets", {
							required: "# of Plus-Ones is required",
							valueAsNumber: true,
							min: {
								value: 0,
								message:
									"Additional Tickets quantity must be greater than or equal to 0",
							},
							validate: (spotsForPlusOnes) => {
								if (queryParamsInviteIdIsNumber) return null;

								const spotsForInvite = 1;
								const spotsToBeUsedInRegistration =
									spotsForPlusOnes + spotsForInvite;

								if (spotsToBeUsedInRegistration <= remainingSpots) return null;

								return `You are trying to register for more tickets than are available (${remainingSpots} ${
									remainingSpots === 1 ? "ticket" : "tickets"
								} remaining). Please edit your guest list or reach out to your event manager for assistance.`;
							},
						})}
						className={
							disabled || !additionalTicketsFieldEnabled ? "disabled-field" : ""
						}
						disabled={disabled || !additionalTicketsFieldEnabled}
					/>

					<ErrorMessage
						errors={errors}
						name="additionalTickets"
						render={({ message }) => <p className="error-msg">{message}</p>}
					/>
				</div>
			)}

			{fields.linkedinProfileUrl && (
				<label>
					LinkedIn profile
					<input
						type="text"
						{...register("linkedinProfileUrl")}
						disabled={disabled}
					/>
				</label>
			)}
		</div>
	);
};

export default InviteFormStep;
