import { useEffect } from "react";

import usePost from "../../../hooks/use-post";
import useAppForm from "../../../hooks/use-app-form";

const useQuestionsForm = (icebreakerQuestions, backendHost, inviteId) => {
	const [
		sendQuestionsForm,
		questionsFormResponse,
		questionsFormLoading,
		questionsFormError,
	] = usePost(`${backendHost}/api/v1/user_icebreaker_answers`);

	const defaultValues = () => {
		const values = {};

		icebreakerQuestions.forEach((question) => {
			const { id, answer } = question;
			values[Number(id).toString()] = answer?.answer ?? "";
		});

		return values;
	};

	const { register, handleSubmit, formState, control, setValue } = useAppForm({
		mode: "all",
		defaultValues: defaultValues(),
	});

	useEffect(() => {
		if (icebreakerQuestions) {
			icebreakerQuestions.forEach((question) => {
				const { id, answer } = question;
				setValue(Number(id).toString(), answer?.answer ?? "");
			});
		}
	}, [icebreakerQuestions, setValue]);

	const onSubmit = (data) => {
		const questionIds = Object.keys(data);

		const answers = questionIds.map((id) => {
			const question = icebreakerQuestions.find(
				(icebreakerQuestion) =>
					icebreakerQuestion.id.toString() === id.toString()
			);

			return {
				icebreaker_question_id: id,
				answer: !!question.answer
					? {
							...question.answer,
							answer: data[id],
					  }
					: { answer: data[id] },
			};
		});

		sendQuestionsForm({ invite_id: inviteId, answers });
	};

	return {
		questionsFormRegister: register,
		questionsFormState: formState,
		questionsFormControl: control,
		sendQuestionsForm: handleSubmit(onSubmit),
		questionsFormResponse,
		questionsFormLoading,
		questionsFormResponseSucceed:
			!!questionsFormResponse && !questionsFormError && !questionsFormLoading,
	};
};

export default useQuestionsForm;
