// Library Imports
import { useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";

// Hooks
import useGetQueryParams from "../../../hooks/use-get-query-params";
import usePost from "../../../hooks/use-post";
import useAppForm from "../../../hooks/use-app-form";

const useInviteForm = (
	backendHost,
	inviteId,
	setInviteId,
	permalinkToken,
	setIcebreakerQuestions,
	setInviteHasCompletedWaiver,
	setRemainingPlusOnes,
	setPlusOnesStepEnabled,
	setIcebreakerQuestionsStepEnabled,
	tiers,
	setInviteEmail,
	setInviteRsvpStatus,
	paymentAttempted,
	setInviteProfilePicture,
	setInviteLandingPageUrl,
	setInviteTier,
	setInviteTicketAssigned,
	setInviteMagicUrlForEventWalletModal
) => {
	const queryParams = useGetQueryParams();
	const persistenceKey =
		(inviteId && `invite${inviteId}-registration-form`) ||
		(queryParams.inviteId && `invite${queryParams.inviteId}-registration-form`);
	const guestRepId =
		queryParams.guestRep?.split("-")[0] || queryParams.repId || null;

	const paymentCancelledIsNumber = !isNaN(
		parseInt(queryParams.paymentCancelled)
	);
	const fillInviteFormWithPersistedData =
		paymentAttempted || paymentCancelledIsNumber || !!queryParams.allSet;

	const [additionalTicketsFieldEnabled, setAdditionalTicketsFieldEnabled] =
		useState(true);
	const [inviteFormBackendErrorMsg, setInviteFormBackendErrorMsg] =
		useState(null);

	const [
		sendInviteForm,
		inviteFormResponse,
		inviteFormLoading,
		inviteFormError,
	] = usePost(`${backendHost}/api/v1/guest/events/${permalinkToken}/invites`);

	const { register, handleSubmit, formState, setValue, watch } = useAppForm(
		{
			mode: "all",
			defaultValues: {
				firstName: "",
				lastName: "",
				title: "",
				company: "",
				address: "",
				city: "",
				state: "",
				zip: "",
				email: "",
				phone: "",
				additionalTickets: 0,
				tier: "",
				linkedinProfileUrl: "",
				guestRepId,
			},
		},
		{
			key: persistenceKey,
			fillFormWithPersistedData: fillInviteFormWithPersistedData,
		}
	);

	const onSubmit = (data) => {
		setInviteFormBackendErrorMsg(null);
		const queryParamsInviteIdIsNumber = !isNaN(parseInt(queryParams.inviteId));
		const queryParamPrevInviteId = !isNaN(parseInt(queryParams.prevInviteId));
		const optionalUserFields = {};
		const optionalFields = {};

		if (queryParamsInviteIdIsNumber)
			optionalUserFields.id = queryParams.inviteId;

		if (queryParamPrevInviteId)
			optionalFields.invite_id_to_decline = queryParams.prevInviteId;

		sendInviteForm({
			...optionalFields,
			user: {
				...optionalUserFields,
				first_name: data.firstName,
				last_name: data.lastName,
				title: data.title,
				email: data.email,
				phone: data.phone,
				company: data.company,
				address: data.address,
				city: data.city,
				state: data.state,
				zip: data.zip,
				additional_tickets: data.additionalTickets,
				tier: data.tier,
				linkedin_profile_url: data.linkedinProfileUrl,
				rep_id: data.guestRepId,
			},
		});
	};

	useEffect(() => {
		if (inviteFormResponse) {
			if (inviteFormResponse.icebreakerQuestions) {
				setIcebreakerQuestions(inviteFormResponse.icebreakerQuestions);
				setIcebreakerQuestionsStepEnabled(
					!_isEmpty(inviteFormResponse.icebreakerQuestions)
				);
			}

			if (inviteFormResponse.id) {
				setInviteId(inviteFormResponse.id);
			}

			setInviteEmail(inviteFormResponse.invitee.email);
			setInviteRsvpStatus(inviteFormResponse.rsvp_status);
			setRemainingPlusOnes(inviteFormResponse.plusOnesRemaining);
			setPlusOnesStepEnabled(inviteFormResponse.plusOnesPermitted);
			setInviteHasCompletedWaiver(inviteFormResponse.hasCompletedWaiver);
			setInviteProfilePicture(inviteFormResponse.linkedinProfilePictureUrl);
			setInviteLandingPageUrl(inviteFormResponse.landingPageUrl);
			setInviteTier({ free: inviteFormResponse.tierIsFree });
			setInviteTicketAssigned(inviteFormResponse.ticketAssigned);
			setInviteMagicUrlForEventWalletModal(
				inviteFormResponse.magicUrlForEventWalletModal
			);
		}
	}, [inviteFormResponse]);

	useEffect(() => {
		if (inviteFormError) {
			setInviteFormBackendErrorMsg(inviteFormError.errors);
		}
	}, [inviteFormError]);

	useEffect(() => {
		if (tiers.length === 1) {
			setValue("tier", tiers[0].id);
		}
	}, []);

	return {
		inviteFormRegister: register,
		inviteFormState: formState,
		sendInviteForm: handleSubmit(onSubmit),
		inviteFormResponse,
		inviteFormLoading,
		inviteFormResponseSucceed: !!inviteFormResponse && !inviteFormError,
		setInviteFormValue: setValue,
		inviteFormValues: watch(),
		inviteFormBackendErrorMsg,
		additionalTicketsFieldEnabled,
		setAdditionalTicketsFieldEnabled,
	};
};

export default useInviteForm;
