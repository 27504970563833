// Library Imports
import { useState, useContext, useEffect } from "react";
import { TicketingFormContext } from "../../contexts/ticketing";

// Components
import TotalPrice from "./total-price";

const FormStep = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [mobile, setMobile] = useState("");

	const {
		event: { availableTickets: tickets, marketplaceName, marketplaceUrl },
		formValidationError,
		handleTicketChange,
		selectedTickets,
	} = useContext(TicketingFormContext);

	useEffect(() => {
		const formValues = JSON.parse(localStorage.getItem("invite-info"));

		if (formValues) {
			setFirstName(formValues["first-name"]);
			setLastName(formValues["last-name"]);
			setEmail(formValues["email"]);
			setMobile(formValues["mobile"]);
		}
	}, []);

	return (
		<>
			<h2 className="personal-info-form-title">Personal Details</h2>

			<div className="warning-msg">
				⚠ Tickets will be provided by{" "}
				<a href={marketplaceUrl} target="_blank" rel="noreferrer">
					{marketplaceName}
				</a>
				. Please confirm that you have an account created with this ticket
				provider and ensure that you enter that email below.
			</div>

			<form>
				{/* First Name */}
				<label>
					First Name<span className="required">*</span>
					<input
						name="first-name"
						required
						onChange={(e) => setFirstName(e.target.value)}
						value={firstName}
						autoComplete="given-name"
					/>
				</label>

				{/* Last Name */}
				<label>
					Last Name<span className="required">*</span>
					<input
						name="last-name"
						required
						onChange={(e) => setLastName(e.target.value)}
						value={lastName}
						autoComplete="family-name"
					/>
				</label>

				{/* Email */}
				<label>
					Email<span className="required">*</span>
					<input
						name="email"
						type="email"
						required
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						autoComplete="email"
					/>
				</label>

				{/* Mobile Number */}
				<label>
					Mobile Number<span className="required">*</span>
					<input
						name="mobile"
						required
						onChange={(e) => setMobile(e.target.value)}
						value={mobile}
						autoComplete="tel"
					/>
				</label>

				{/* Tickets */}
				<div className="multiselect-checkbox-group">
					<label>
						<span>
							Select Your Tickets<span className="required">*</span>
						</span>
					</label>

					{tickets.map((ticket) => (
						<label key={`checkbox-for-${ticket.id}`}>
							<input
								type="checkbox"
								value={ticket.id}
								onChange={handleTicketChange}
								defaultChecked={selectedTickets.includes(String(ticket.id))}
							/>
							Section: {ticket.section} | Row: {ticket.group_number} | Seat:{" "}
							{ticket.subgroup_number}
						</label>
					))}
				</div>

				{formValidationError && (
					<p className="error-msg">{formValidationError}</p>
				)}

				<TotalPrice />

				<button
					id="personal-info-submit"
					type="submit"
					className="hidden"
				></button>
			</form>
		</>
	);
};

export default FormStep;
